import React from 'react';
import { Grid, Typography, typographyClasses, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { PaletteColor } from '@vizsla/theme';
import { CampaignTeamMember } from '@vizsla/graphql';
import { formatDate } from '@vizsla/utils';
import { DateFormatPatterns } from '@vizsla/constants';
import { TableColumn, TableData, UserAvatarCaption } from '@vizsla/components';
import { useMediaBreakpoints } from '@vizsla/hooks';

const useStyles = makeStyles({
  user: {
    '& button': {
      textAlign: 'left',
    },
    '& button p': {
      fontSize: 'clamp(14px, 2vw, 16px)',
    },
  },
  subTitle: {
    [`&.${typographyClasses.root}`]: {
      color: PaletteColor.GrayText,
    },
  },
  tableRowAction: {
    [`&.${typographyClasses.root}`]: {
      color: PaletteColor.DefaultText,
    },
  },
  activityText: {
    '&': {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
    },
  },
});

interface MembersTableProps {
  members: CampaignTeamMember[] | null | undefined;
  styles?: any;
  loading?: boolean;
  hideTitle?: boolean;
}

export const MembersTable: React.FC<MembersTableProps> = ({
  styles,
  members,
  loading = false,
  hideTitle = true,
}) => {
  const classes = useStyles();
  const { pageBackgroundSecondColor, accentColor } = styles;
  const { isSm } = useMediaBreakpoints();

  const columns: Array<TableColumn<CampaignTeamMember>> = [
    {
      key: 'user',
      title: 'user',
      render: (member: CampaignTeamMember) => {
        return (
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
            sx={{
              backgroundColor: pageBackgroundSecondColor || PaletteColor.White,
              borderColor: accentColor || PaletteColor.Black,
            }}
          >
            <Grid item width="100%" className={classes.user}>
              <UserAvatarCaption
                user={member.attendee?.user}
                additionalCaption={
                  <Typography variant="caption" className={classes.tableRowAction}>
                    {formatDate(
                      member.attendee?.user?.createdAt,
                      DateFormatPatterns.shortMonthDayYear,
                    )}
                  </Typography>
                }
                showAvatar={!isSm}
              />
            </Grid>
            <Grid item className={classes.activityText}>
              <Box textAlign="right">
                <Typography variant="caption" className={classes.tableRowAction}>
                  {member.attendee?.registrationOption?.name}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Typography variant="caption" className={classes.subTitle}>
        Team Members
      </Typography>
      <TableData
        hideTitle={hideTitle}
        dataSource={members ?? []}
        columns={columns}
        loading={loading}
      />
    </React.Fragment>
  );
};

import { useCampaignFaQsListQuery } from '@vizsla/graphql';
import { CampaignFaqAllocation } from '@vizsla/types';

import { useCurrentPublicCampaign } from 'src/hooks/campaign';
import { CampaingOrder } from 'src/types/campaignFAQs';

export const usePublicCampaignFAQs = (userGroup: CampaignFaqAllocation, experience: string) => {
  const { campaign } = useCurrentPublicCampaign();
  const filterByCampaignId = {
    campaign: {
      id: {
        equals: campaign?.id,
      },
    },
  };

  const { data, loading } = useCampaignFaQsListQuery({
    variables: {
      filter: filterByCampaignId,
    },
  });

  const campaignFaqs = data?.campaignFAQSList.items || [];
  const campaignFaqExperience = campaignFaqs.filter(faq => {
    const allocationIdArray = faq.allocation?.items.map(experience => experience.id);
    return allocationIdArray?.includes(experience) ? faq : null;
  });
  const campaignByOrder: CampaingOrder = {
    [CampaignFaqAllocation.Attendee]: [],
    [CampaignFaqAllocation.Donor]: [],
    campaign: campaign ?? {},
  };

  campaignFaqExperience.forEach((faq: any) => {
    if (faq?.userGroup?.includes(CampaignFaqAllocation.Donor)) {
      campaignByOrder?.[CampaignFaqAllocation.Donor].push(faq);
    }
    if (faq?.userGroup?.includes(CampaignFaqAllocation.Attendee)) {
      campaignByOrder?.[CampaignFaqAllocation.Attendee].push(faq);
    }
  });

  const allocationCampaignFaqs = campaignFaqExperience.filter(faq =>
    faq?.userGroup?.includes(userGroup),
  );

  return {
    allocationCampaignFaqs,
    campaignFaqExperience: campaignByOrder,
    loading,
  };
};

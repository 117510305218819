import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, CircularProgress, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useModal } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';

const useStyles = makeStyles((theme: Theme) => ({
  modalComponent: {
    '& .MuiDialog-paper': {
      maxWidth: '450px',
      width: '90%',
      wordSpacing: '1px',
    },
  },
}));

export const Warning = () => {
  const { isOpen, closeModal, args } = useModal(MODALS.WARNING);
  const navigate = useNavigate();
  const classes = useStyles();

  const message = React.useMemo(() => {
    if (args) {
      return args.message;
    }
    return '';
  }, [args]);

  const url = React.useMemo(() => {
    if (args && args.url) {
      return args.url;
    }
    return undefined;
  }, [args]);

  const onClick = () => {
    closeModal();
    if (url) {
      navigate(url);
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={closeModal} customClasses={[classes.modalComponent]}>
      <Modal.Title>
        <Typography variant="h2" align="center">
          WARNING
        </Typography>
      </Modal.Title>
      {!args && (
        <Box display="flex" justifyContent="flex-center" alignItems="center" pr={2}>
          <CircularProgress size={20} />
        </Box>
      )}
      <Modal.Content>
        <Typography align="center" textAlign="left" fontSize={16} lineHeight="25px" mb="1.3rem">
          {message}
        </Typography>
      </Modal.Content>
      <Modal.Actions>
        <Modal.Action type="secondary" content="Cancel" onAction={closeModal} />
        <Modal.Action type="primary" content="Continue" onAction={onClick} />
      </Modal.Actions>
    </Modal>
  );
};

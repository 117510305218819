import React from 'react';

import { AsyncContent } from '@vizsla/components';

import { Container, SubTitle, Title } from './PurchaseDescriptorHeader.style';
import { PurchaseDescriptorHeaderSkeleton } from './PurchaseDescriptorHeader.skeleton';

interface PurchaseDescriptorHeaderProps {
  subtitle: string;
  title?: string;
  loading?: boolean;
}
export const PurchaseDescriptorHeader: React.FC<PurchaseDescriptorHeaderProps> = props => (
  <AsyncContent loading={props.loading} skeleton={PurchaseDescriptorHeaderSkeleton}>
    <Container>
      <SubTitle>{props.subtitle}</SubTitle>
      <Title>{props.title}</Title>
    </Container>
  </AsyncContent>
);

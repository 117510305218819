import React, { useState, useMemo, useEffect, Fragment } from 'react';
import { Checkbox, Typography, FormControlLabel, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FontSize, PaletteColor } from '@vizsla/theme';
import { useExperienceLandingQuery } from '@vizsla/graphql';
import {
  calculateShoppingCartTotal,
  calculateShoppingCartTotalWithoutDonation,
  calculateShoppingCartItemsTotal,
  calculateShoppingCartTotalWitOutPaymentFee,
  calculateDiscount,
  calculateShoppingCartTotalWitOutPaymentFeeAndDonation,
} from '@vizsla/utils';
import {
  selectAllVizslaFee,
  selectAllPaymentFee,
  selectAllRoundUp,
  selectAllTaxes,
  useShoppingCartState,
  selectAllDonations,
  selectAllRegistrations,
  selectAllAssets,
} from '@vizsla/hooks';
import { ExperienceType } from '@vizsla/types';

const useStyles = makeStyles((theme: Theme) => ({
  checkboxLabel: {
    fontSize: FontSize.Default,
    color: PaletteColor.BlackText,
    marginRight: 0,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      '& > span:first-of-type': {
        paddingTop: '2px',
      },
    },
  },
  checkboxStyle: {
    '&.MuiButtonBase': {
      padding: '6px',
    },
  },
}));

const selectFeeState = state => ({
  vizslaFee: selectAllVizslaFee(state).at(0),
  paymentFee: selectAllPaymentFee(state).at(0),
  roundUp: selectAllRoundUp(state).at(0),
  taxesRate: selectAllTaxes(state).at(0),
  donations: selectAllDonations(state),
  registrations: selectAllRegistrations(state),
  assets: selectAllAssets(state),
  addCart: state.add,
  removeCart: state.remove,
  items: state.items,
});
export const ExperienceFee = ({ experienceId, recalculate }) => {
  const classes = useStyles();
  const [isCheckedFee, setIsCheckedFee] = useState(true);
  const [isCheckedRound, setIsCheckedRound] = useState(false);
  const [oldVislafeeValue, setViszlafeeValue] = useState(0);
  const [vislafeeChange, setViszlafeeChange] = useState(false);
  const {
    vizslaFee,
    paymentFee,
    roundUp,
    addCart,
    removeCart,
    taxesRate,
    items,
    donations,
    registrations,
    assets,
  } = useShoppingCartState(selectFeeState);
  const { data } = useExperienceLandingQuery({ variables: { id: experienceId } });
  const handleChangeFee = event => {
    const value = event.target.checked as boolean;
    if (vizslaFee) removeCart(vizslaFee);
    if (taxesRate) removeCart(taxesRate);
    setIsCheckedFee(value);
    if (value) {
      const vizlaFee = fee ? parseFloat(((fee / 100) * totalItemsCart).toFixed(2)) : 0;
      const taxes = taxRate ? parseFloat(((taxRate / 100) * vizlaFee).toFixed(2)) : 0;
      if (oldVislafeeValue !== vizlaFee) {
        setViszlafeeChange(true);
        setViszlafeeValue(vizlaFee);
      } else {
        setViszlafeeChange(false);
      }
      addCart({
        type: 'vizslaFee',
        price: vizlaFee,
        vizslaFee: vizlaFee,
      });
      addCart({
        type: 'tax',
        price: taxes,
        taxes,
      });
    } else {
      const vizlaFee = fee ? parseFloat(((fee / 100) * totalCartWithOutDonation).toFixed(2)) : 0;
      const taxes = taxRate ? parseFloat(((taxRate / 100) * vizlaFee).toFixed(2)) : 0;
      const vizslaFeeComplete = fee ? parseFloat(((fee / 100) * totalItemsCart).toFixed(2)) : 0;
      const taxesComplete = taxRate
        ? parseFloat(((taxRate / 100) * vizslaFeeComplete).toFixed(2))
        : 0;
      if (oldVislafeeValue !== vizlaFee) {
        setViszlafeeChange(true);
        setViszlafeeValue(vizlaFee);
      } else {
        setViszlafeeChange(false);
      }
      addCart({
        type: 'vizslaFee',
        price: vizlaFee,
        vizslaFee: vizlaFee,
        vizslaFeeComplete,
      });
      addCart({
        type: 'tax',
        price: taxes,
        taxes,
        taxesComplete:
          donations.length > 0 && registrations.length === 0 && assets.length === 0
            ? taxesComplete
            : taxes,
      });
    }
  };
  const handleChangeRoundUp = event => {
    const value = event.target.checked as boolean;
    setIsCheckedRound(value);
    if (value) {
      const price = parseFloat((Math.ceil(totalCart) - totalCart).toFixed(2));
      const percentage = (stripeFee as number) / 100;
      const percentageWithConstant = 1 - percentage;
      const roundUp = parseFloat((price * percentageWithConstant).toFixed(2));

      const paymentFee = parseFloat((price - roundUp).toFixed(2));
      addCart({
        type: 'roundup',
        price,
        paymentFee,
        roundUp,
      });
    } else {
      removeCart(roundUp);
    }
  };

  const feeEnable = useMemo(() => {
    return data?.experience?.fundraisingSettings?.donationSettings?.feesEnabled;
  }, [data]);
  const feeMessage = useMemo(() => {
    return data?.experience?.fundraisingSettings?.donationSettings?.feesMessage;
  }, [data]);
  const roundUpEnable = useMemo(() => {
    return data?.experience?.fundraisingSettings?.donationSettings?.roundupEnabled;
  }, [data]);
  const roundUpMessage = useMemo(() => {
    return data?.experience?.fundraisingSettings?.donationSettings?.roundupMessage;
  }, [data]);
  const fee = useMemo(() => {
    return data?.experience?.campaign?.vizslaOrganization?.fee;
  }, [data]);
  const stripeFee = useMemo(() => {
    return data?.experience?.campaign?.vizslaOrganization?.stripeFee;
  }, [data]);
  const taxRate = useMemo(() => {
    return data?.experience?.campaign?.vizslaOrganization?.taxRate;
  }, [data]);
  const totalCart = useMemo(() => {
    return calculateShoppingCartTotal(items);
  }, [items]);
  const totalItemsCart = useMemo(() => {
    return calculateShoppingCartItemsTotal(items);
  }, [items]);
  const totalCartWithOutDonation = useMemo(() => {
    return calculateShoppingCartTotalWithoutDonation(items);
  }, [items]);
  const totalCartWithOutPaymentFee = useMemo(() => {
    return calculateShoppingCartTotalWitOutPaymentFee(items);
  }, [items]);
  const totalCartWithOutPaymentFeeAndDonations = useMemo(() => {
    return calculateShoppingCartTotalWitOutPaymentFeeAndDonation(items);
  }, [items]);
  const totalDiscount = useMemo(() => {
    return calculateDiscount(items);
  }, [items]);
  useEffect(() => {
    if (vislafeeChange && isCheckedFee) {
      setViszlafeeChange(false);
      if (paymentFee) removeCart(paymentFee);
      addCart({
        type: 'paymentFee',
        price: stripeFee
          ? parseFloat(
              (
                (totalCartWithOutPaymentFee + 0.3) / (1 - stripeFee / 100) -
                totalCartWithOutPaymentFee
              ).toFixed(2),
            )
          : 0,
        paymentFee: stripeFee
          ? parseFloat(
              (
                (totalCartWithOutPaymentFee + 0.3) / (1 - stripeFee / 100) -
                totalCartWithOutPaymentFee
              ).toFixed(2),
            )
          : 0,
      });
    }

    // No cover fee
    if (vislafeeChange && !isCheckedFee) {
      setViszlafeeChange(false);
      if (paymentFee) removeCart(paymentFee);
      // payment Fee with rate stripe (+0.3)
      if (registrations.length > 0 || assets.length > 0) {
        addCart({
          type: 'paymentFee',
          price: stripeFee
            ? parseFloat(
                (
                  (totalCartWithOutPaymentFeeAndDonations + 0.3) / (1 - stripeFee / 100) -
                  totalCartWithOutPaymentFeeAndDonations
                ).toFixed(2),
              )
            : 0,
          paymentFee: stripeFee
            ? parseFloat(
                (
                  (totalCartWithOutPaymentFeeAndDonations + 0.3) / (1 - stripeFee / 100) -
                  totalCartWithOutPaymentFeeAndDonations
                ).toFixed(2),
              )
            : 0,
          paymentFeeComplete: stripeFee
            ? parseFloat(
                (
                  (totalCartWithOutPaymentFee + 0.3) / (1 - stripeFee / 100) -
                  totalCartWithOutPaymentFee
                ).toFixed(2),
              )
            : 0,
        });
      }

      if (donations.length > 0 && registrations.length === 0 && assets.length === 0) {
        // payment Fee without rate stripe (+0.3)
        addCart({
          type: 'paymentFee',
          price: stripeFee
            ? parseFloat(
                (
                  totalCartWithOutPaymentFeeAndDonations / (1 - stripeFee / 100) -
                  totalCartWithOutPaymentFeeAndDonations
                ).toFixed(2),
              )
            : 0,
          paymentFee: stripeFee
            ? parseFloat(
                (
                  totalCartWithOutPaymentFeeAndDonations / (1 - stripeFee / 100) -
                  totalCartWithOutPaymentFeeAndDonations
                ).toFixed(2),
              )
            : 0,
          paymentFeeComplete: stripeFee
            ? parseFloat(
                (
                  (totalCartWithOutPaymentFee + 0.3) / (1 - stripeFee / 100) -
                  totalCartWithOutPaymentFee
                ).toFixed(2),
              )
            : 0,
        });
      }
    }
  }, [vislafeeChange]);
  useEffect(() => {
    if (vizslaFee) removeCart(vizslaFee);
    if (taxesRate) removeCart(taxesRate);
    if (feeEnable && isCheckedFee) {
      const vizlaFee = fee ? parseFloat(((fee / 100) * totalItemsCart).toFixed(2)) : 0;
      const taxes = taxRate ? parseFloat(((taxRate / 100) * vizlaFee).toFixed(2)) : 0;
      if (oldVislafeeValue !== vizlaFee) {
        setViszlafeeChange(true);
        setViszlafeeValue(vizlaFee);
      } else {
        setViszlafeeChange(false);
      }
      addCart({
        type: 'vizslaFee',
        price: vizlaFee,
        vizslaFee: vizlaFee,
      });
      addCart({
        type: 'tax',
        price: taxes,
        taxes,
      });
    } else {
      const vizlaFee = fee ? parseFloat(((fee / 100) * totalCartWithOutDonation).toFixed(2)) : 0;
      const taxes = taxRate ? parseFloat(((taxRate / 100) * vizlaFee).toFixed(2)) : 0;
      const vizslaFeeComplete = fee ? parseFloat(((fee / 100) * totalItemsCart).toFixed(2)) : 0;
      const taxesComplete = taxRate
        ? parseFloat(((taxRate / 100) * vizslaFeeComplete).toFixed(2))
        : 0;
      if (oldVislafeeValue !== vizlaFee) {
        setViszlafeeChange(true);
        setViszlafeeValue(vizlaFee);
      } else {
        setViszlafeeChange(false);
      }
      addCart({
        type: 'vizslaFee',
        price: vizlaFee,
        vizslaFee: vizlaFee,
        vizslaFeeComplete,
      });
      addCart({
        type: 'tax',
        price: taxes,
        taxes,
        taxesComplete:
          donations.length > 0 && registrations.length === 0 && assets.length === 0
            ? taxesComplete
            : taxes,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeEnable, recalculate, totalDiscount]);

  const experienceType = data?.experience?.experienceType;
  return (
    <Grid container direction="column" spacing={1} mt="1rem">
      {experienceType !== ExperienceType.ticketing && (
        <Fragment>
          <Grid item xs={12}>
            {feeEnable && (
              <FormControlLabel
                className={classes.checkboxLabel}
                label={feeMessage}
                control={
                  <Checkbox
                    name="allowExcludedBibNumbers"
                    checked={isCheckedFee}
                    onChange={handleChangeFee}
                    className={classes.checkboxStyle}
                  />
                }
              />
            )}
          </Grid>
        </Fragment>
      )}

      <Grid item xs={12}>
        {roundUpEnable && (
          <FormControlLabel
            className={classes.checkboxLabel}
            label={roundUpMessage}
            control={
              <Checkbox
                name="allowExcludedBibNumbers"
                checked={isCheckedRound}
                onChange={handleChangeRoundUp}
                className={classes.checkboxStyle}
              />
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

import React from 'react';
import { Grid, Theme, Typography, typographyClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { PaletteColor } from '@vizsla/theme';

import { ContentBox } from 'src/components/shared';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    padding: theme.spacing(2, 0),
    [`& .${typographyClasses.root}`]: {
      color: PaletteColor.GrayText,
    },
  },
}));

export const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.footer}>
      <ContentBox>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="caption">Powered by Collie</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">
              {/* TODO replace with actual links */}
              Copyright &#169; {new Date().getFullYear()} - All Rights Reserved - Terms of Use -
              Privacy Policy - Cookie Statement
            </Typography>
          </Grid>
        </Grid>
      </ContentBox>
    </Grid>
  );
};

import React from 'react';
import { Grid } from '@mui/material';

import { CampaignTeamMember, Team_FundraiserLandingItem } from '@vizsla/graphql';
import { TableData } from '@vizsla/components';
import { useMediaBreakpoints } from '@vizsla/hooks';

import { useRaisedTable } from '../hooks/useRaisedTable';
import { MEMBERS } from '../constants/raisedTypes';

interface MembersTableProps {
  members: CampaignTeamMember[] | null | undefined;
  styles?: any;
  loading?: boolean;
  type?: string;
  team: Team_FundraiserLandingItem;
}

export const RaisedTable: React.FC<MembersTableProps> = ({
  styles,
  members,
  loading = false,
  type = MEMBERS,
  team,
}) => {
  const { getColumnConfiguration } = useRaisedTable();
  const columns = getColumnConfiguration(type, team);
  const { isSm } = useMediaBreakpoints();

  return (
    <Grid
      sx={
        isSm
          ? {
              '& table th': {
                padding: '8px 6px',
              },
            }
          : {}
      }
    >
      <TableData dataSource={members ?? []} columns={columns} loading={loading} />
    </Grid>
  );
};

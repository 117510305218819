import { Button, FormHelperText, Skeleton, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Form } from 'react-final-form';
import { LoadingButton } from '@mui/lab';

import { SelectFieldOption } from '@vizsla/types';
import { Field, SelectField } from '@vizsla/components';
import { TeamFundraising, TeamFundraisingKeyFilter } from '@vizsla/graphql';
import { useShoppingCartState } from '@vizsla/hooks';
import { isShoppingCartRegistrationItem } from '@vizsla/utils';

import { Buttons, Container, Content } from './ExperienceTeamJoin.styles';
import { useExperienceTeams } from './useExperienceTeams';

interface Values {
  team: TeamFundraising | undefined;
}

interface Props {
  onSelected(team: TeamFundraisingKeyFilter): void;
  onDismiss(): void;
}

export function ExperienceTeamJoin(props: Props) {
  const { data: teams, loading } = useExperienceTeams();
  const { items: cart, setMany: setManyCart } = useShoppingCartState();

  const initials = useMemo<Values>(() => {
    const option = cart.find(isShoppingCartRegistrationItem);
    const selected = teams.find(team => team.id === option?.team?.id);

    if (selected) {
      return { team: selected as TeamFundraising };
    }

    return { team: undefined };
  }, [teams, cart]);

  const options = useMemo(() => {
    return teams.map<SelectFieldOption>(team => {
      return {
        key: team.id,
        label: team.name as string,
        // Cast to `string` to send through types
        // I don't want to break the entire codebase because of transformation `string` to `object`
        // Whatever, this works because internally `SelectField` support any `value` as value.
        value: team as string,
      };
    });
  }, [teams]);

  const onSubmit = (values: Values) => {
    if (values.team === undefined) {
      onDismiss();
      return;
    }

    const registrations = cart
      // Filter by registration options only.
      .filter(isShoppingCartRegistrationItem)
      // Set to all the registration options the given team.
      .map(registration => {
        const payload: typeof registration = {
          ...registration,

          team: {
            id: values.team?.id as string,
            name: values.team?.name ?? undefined,
          },
        };

        return payload;
      });

    setManyCart(registrations);
    props.onSelected(values.team);
  };

  const onDismiss = () => {
    props.onDismiss();
  };

  if (loading) {
    return (
      <Container>
        <Typography>
          Teams connect you with others and help amplify any fundraising efforts. Choose a team from
          the available options to incorporate all attendees as team members, or proceed without a
          team.
        </Typography>

        <Content>
          <Skeleton variant="rectangular" width="100%" height="3.5rem" />

          <Buttons>
            <Skeleton variant="rectangular" width="12rem" height="3rem" />
            <Skeleton variant="rectangular" width="8rem" height="3rem" />
          </Buttons>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Typography>
        Teams connect you with others and help amplify any fundraising efforts. Choose a team from
        the available options to incorporate all attendees as team members, or proceed without a
        team.
      </Typography>

      <Form initialValues={initials} onSubmit={onSubmit}>
        {form => (
          <Content>
            <Field
              name="team"
              label="Select a team"
              component={SelectField}
              disabled={loading || options.length === 0}
              options={options}
            />

            {!loading && options.length === 0 && (
              <FormHelperText>
                No teams found for the selected experience, you&apos;re free to continue.
              </FormHelperText>
            )}

            <Buttons>
              <Button variant="outlined" onClick={onDismiss}>
                Continue without a team
              </Button>

              <LoadingButton
                loading={loading}
                disabled={!form.values.team}
                onClick={form.handleSubmit}
              >
                Continue
              </LoadingButton>
            </Buttons>
          </Content>
        )}
      </Form>
    </Container>
  );
}

import { create } from 'zustand';

interface CreditCardData {
  saveCreditCard: boolean;
}
interface State {
  data: CreditCardData;
}

const INITIAL_STATE: State = {
  data: {
    saveCreditCard: false,
  },
};

interface CreditCardSettings extends State {
  setData(data: CreditCardData): void;
}

export const useCardSettings = create<CreditCardSettings>(set => ({
  ...INITIAL_STATE,

  setData(data) {
    set({ data });
  },
}));

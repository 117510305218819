import styled from '@emotion/styled';

import { OPTIONAL_BACKGROUND } from 'src/assets';

/// Container

const CONTAINER_OVERLAY_OPACITY = 0.7;
const CONTAINER_SECOND_OPACITY = 0.9;

export const Container = styled.div`
  max-width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: ${p => p.theme.spacing(4)};
  gap: ${p => p.theme.spacing(4)};
  position: relative;
  box-sizing: border-box;
  background-size: cover;
  background-blend-mode: overlay;
  background-color: white;
  /* It will remain commented out until a new background image is defined
  background-image: url(${OPTIONAL_BACKGROUND}),
    linear-gradient(
      rgba(255, 255, 255, ${CONTAINER_OVERLAY_OPACITY}),
      rgba(255, 255, 255, ${CONTAINER_SECOND_OPACITY})
    ); */

  @media (max-width: 700px) {
    padding: 0;
  }

  @media (max-width: 1170px) {
    align-items: initial;
    flex-direction: column;
  }
`;

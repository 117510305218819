import styled from '@emotion/styled';

// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

// Content

export const Content = styled(Container)`
  gap: 1rem;
`;

// Fields

export const Fields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  ${p => p.theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;

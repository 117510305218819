import React, { useMemo, useState, useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Formik } from 'formik';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { Button, Checkbox, FormControlLabel } from '@mui/material';

// import { FormCheckbox } from 'shared/components';
import { paymentMethodFormSchema } from 'src/utils/paymentMethod';
import { useCardSettings } from 'src/hooks/card';

import { Actions, Form, Section, Title } from './PaymentMethodForm.styles';
import { useStyles } from '../CheckoutPage/CheckoutPageOnePage';

export interface Values {
  terms: boolean;
  savePaymentMethod: boolean;
}

const INITIAL: Values = {
  terms: false,
  savePaymentMethod: false,
};

interface Props {
  initial?: Values;

  /** @default "Submit" */
  labelSubmit?: string;

  /** @default "Cancel" */
  labelCancel?: string;
  otherMethod?: boolean;
  existPaymentMethod?: boolean;
  validatePaymentData: boolean;
  setValidatePaymentData(value: boolean): void;
  setCompletePayment(value: boolean): void;
  handleChangeCreditCard: (event: any) => void;
  isCheckedCreditCard?: boolean;

  onSubmit(values: Values, stripe: Stripe, elements: StripeElements): Promise<void>;

  onCancel?(): void;
}

export function PaymentMethodForm(props: Props) {
  const classes = useStyles();
  const { data: creditCardSettings, setData: setSaveCreditCardSettings } = useCardSettings();
  const [submitting, setSubmitting] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const labelCancel = useMemo(() => props.labelCancel ?? 'Cancel', [props.labelCancel]);
  const labelSubmit = useMemo(() => props.labelSubmit ?? 'Submit', [props.labelSubmit]);

  const loading = useMemo(() => submitting, [submitting]);

  const initial: Values = useMemo(() => {
    return { ...props.initial, ...INITIAL };
  }, [props.initial]);
  const handleSubmit = async values => {
    if (!stripe || !elements) return;

    setSubmitting(true);
    await props.onSubmit(values, stripe, elements);
    setSubmitting(false);
  };

  const handleChangeSaveCreditCardFutureUse = event => {
    const value = event.target.checked as boolean;
    setSaveCreditCardSettings({ saveCreditCard: value });
  };

  const handleCancel = () => {
    props.onCancel?.();
  };

  // this onChange is used when it is necesary to set the first payment method for users
  const onChange = async value => {
    if (!props.existPaymentMethod && value.complete) {
      if (!stripe || !elements) return;
      setSubmitting(true);
      props.setCompletePayment(true);
      await props.onSubmit(value, stripe, elements);
      setSubmitting(false);
    }
  };
  useEffect(() => {
    const vaidatePayment = async () => {
      if (props.validatePaymentData) {
        const values = await elements?.submit();
        props.setValidatePaymentData(false);
      }
    };
    vaidatePayment();
  }, [props.validatePaymentData, elements, PaymentElement]);
  return (
    <Formik<Values>
      initialValues={initial}
      validationSchema={paymentMethodFormSchema}
      onSubmit={handleSubmit}
    >
      {form => (
        <Form>
          <Section>
            <Title>Payment Method Information</Title>

            <PaymentElement options={{ business: { name: 'Collie' } }} onChange={onChange} />
          </Section>
          {!props.existPaymentMethod && (
            <FormControlLabel
              classes={{
                label: classes.checkboxLabel,
              }}
              label="Save this credit card for future use"
              control={
                <Checkbox
                  name="saveCreditCard"
                  checked={creditCardSettings?.saveCreditCard}
                  onChange={handleChangeSaveCreditCardFutureUse}
                  className={classes.checkboxStyle}
                />
              }
              sx={{
                marginBottom: '1rem',
              }}
            />
          )}

          {props.existPaymentMethod && (
            <React.Fragment>
              <FormControlLabel
                classes={{
                  label: classes.checkboxLabel,
                }}
                label="Update card on file with new credit card information"
                control={
                  <Checkbox
                    name="updateCardPrimaryMethod"
                    checked={props.isCheckedCreditCard}
                    onChange={props.handleChangeCreditCard}
                    className={classes.checkboxStyle}
                  />
                }
                sx={{
                  marginBottom: '1rem',
                }}
              />
              <Actions>
                <Button disabled={loading} onClick={handleCancel}>
                  {labelCancel}
                </Button>
                <Button disabled={loading || !form.isValid} onClick={handleSubmit}>
                  {labelSubmit}
                </Button>
              </Actions>
            </React.Fragment>
          )}
        </Form>
      )}
    </Formik>
  );
}

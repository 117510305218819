import React, { Fragment } from 'react';
import { Skeleton } from '@mui/material';

import { ThanksPage } from 'src/components';

import { useTeamFundraisingDonate } from '../TeamFundraisingDonate/useTeamFundraisingDonate';

const CARD_SIZE = 220;

export function TeamFundraisingDonationThanks() {
  const { team, fetching: loading } = useTeamFundraisingDonate();

  const name = React.useMemo(() => team?.name ?? 'A fundraising team', [team]);
  const image = React.useMemo(() => team?.avatar?.downloadUrl ?? undefined, [team]);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Fragment>
      {loading ? (
        <Skeleton variant="circular" width={CARD_SIZE} height={CARD_SIZE} />
      ) : (
        <ThanksPage title={name} subtitle={['YOU HAVE SUCCESSFULLY DONATED TO']} uri={image} />
      )}
    </Fragment>
  );
}

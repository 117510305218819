import { get } from 'lodash';
import React from 'react';
import { useForm } from 'react-final-form';

import { AccountIcon, AlertIcon, CheckIcon, Container } from './ExperienceAttendeeTab.styles';

interface Props {
  index: number;
  active: boolean;
  onClick?(): void;
}

export function ExperienceAttendeeTab(props: Props) {
  const formApi = useForm();
  const [isValid, setIsValid] = React.useState(true);

  React.useEffect(() => {
    const unsubscribe = formApi.subscribe(
      form => {
        const errors = form.hasSubmitErrors ? form.submitErrors : form.errors;
        const attendeeErrors = get(errors, `attendees[${props.index}]`, undefined);
        const questionsErrors = get(errors, `questions[${props.index}]`, undefined);
        const hasErrors = Boolean(attendeeErrors || questionsErrors);
        if (isValid === hasErrors) return;
        setIsValid(hasErrors);
      },
      { errors: true, submitErrors: true, hasSubmitErrors: true },
    );
    return () => unsubscribe();
  }, [formApi, isValid, props.index]);

  return (
    <Container
      label={`Attendee ${props.index + 1}`}
      value={props.index}
      active={props.active}
      onClick={props.onClick}
      disableRipple
      icon={
        <React.Fragment>
          <AccountIcon />

          {isValid ? <AlertIcon /> : <CheckIcon />}
        </React.Fragment>
      }
    />
  );
}

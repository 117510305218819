// Hook to validate if the email is available
import { useCallback } from 'react';

import { useAttendeeEmailAlreadyRegisteredLazyQuery } from '@vizsla/graphql';

interface AvailableEmailValidationInput {
  email: string;
  experienceId: string;
}
export function useValidateEmailAvailability() {
  const [validateAttendee] = useAttendeeEmailAlreadyRegisteredLazyQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
  });

  /**
   * Async function to validate if the email is available for the experience
   * @param {AvailableEmailValidationInput} variables - email and experienceId
   * @returns {Promise<boolean>} - true if the email is available
   */
  return useCallback(
    async (variables: AvailableEmailValidationInput) => {
      const { data } = await validateAttendee({ variables });
      return Number(data?.attendeesList?.count) === 0;
    },
    [validateAttendee],
  );
}

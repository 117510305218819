import React, { useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';

import { AsyncContent, Button } from '@vizsla/components';
import { selectAllRegistrations, useModal, useShoppingCartState } from '@vizsla/hooks';
import {
  TicketingOption,
  useCheckSoldOutRegistrationQuery,
  usePublicClientUserQuery,
} from '@vizsla/graphql';

import { Container, FormActions } from './TicketingOptionForm.styles';
import { RegistrationsOptionFormSkeleton } from './TicketingOptionForm.skeleton';
import { useAvailableTicketingOption } from '../../../hooks';
import { TicketingOptionItem } from './TikcetingOptionItem/TicketingOptionItem';
import { ExperienceOptionModal } from '../ExperienceOptionModal/ExperienceOptionModal';
import { MODALS } from '../../../constants/modals';
import { useEventStoreContext } from '../../../providers/EventStoreProvider';

const selectRegistrationsOptionsState = state => {
  const formIsValid = selectAllRegistrations(state).length > 0;
  return {
    formIsValid,
    addItem: state.add,
    removeItem: state.remove,
  };
};
export interface ExperienceOptionStepFormProps {
  experienceId?: string | null;
  onSubmit: () => void;
  earlyBirdValidated: boolean;
}

export function TicketingOptionForm({ experienceId, onSubmit, earlyBirdValidated }) {
  const { formIsValid, addItem, removeItem } = useShoppingCartState(
    selectRegistrationsOptionsState,
  );
  const { setRegistration } = useEventStoreContext();
  const [showError, setShowError] = useState(false);
  const { data, loading } = useAvailableTicketingOption({
    filter: { experience: { id: { equals: experienceId } } },
    skip: !experienceId,
    earlyBirdValidated,
  });
  const { openModal } = useModal();
  const { data: userData } = usePublicClientUserQuery();
  const hasError = showError && !formIsValid;

  const registrationOptions = useCheckSoldOutRegistrationQuery({
    variables: { amountOfRegistered: 0, experienceId },
    skip: !experienceId,
  });

  const isSoldOutRegistrationOption = (option: TicketingOption) => {
    return registrationOptions?.data?.checkSoldOutRegistration?.registrations?.find(
      opt => opt?.registerId === option.id,
    )?.isSoldOut;
  };

  const handleMoreDetailsClick = (option: TicketingOption) => () => {
    openModal(MODALS.REGISTRATION_DETAILS_MODAL, option);
  };

  const handleSubmit = () => {
    setShowError(true);
    setRegistration(true);
    if (formIsValid) onSubmit();
  };
  const user = useMemo(() => {
    return userData?.user;
  }, [userData]);

  return (
    <AsyncContent loading={loading} skeleton={RegistrationsOptionFormSkeleton}>
      <ExperienceOptionModal />
      <Container>
        {data!.map(option => (
          <TicketingOptionItem
            key={option.id}
            option={option}
            user={user}
            onMoreDetailsClick={handleMoreDetailsClick(option)}
            onIncrease={addItem}
            onDecrease={removeItem}
            isSoldOut={isSoldOutRegistrationOption(option)}
            experienceId={experienceId}
          />
        ))}
        {hasError && (
          <Typography paragraph m={0} variant="caption" color="error" align="center" width="100%">
            Please select at least one option
          </Typography>
        )}

        <FormActions>
          <Button variant="contained" type="button" loading={loading} onClick={handleSubmit}>
            Continue
          </Button>
        </FormActions>
      </Container>
    </AsyncContent>
  );
}

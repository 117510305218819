import React, { useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';

import { ThanksPage } from 'src/components';
import { useFundraiserLandingData } from 'src/hooks';

const CARD_SIZE = 220;

export function FundraiserDonationThanks() {
  const { fundraiserId } = useParams();
  const { fundraiser, loading } = useFundraiserLandingData(fundraiserId as string);

  const name = React.useMemo(() => fundraiser?.title ?? 'A fundraiser', [fundraiser]);
  const image = React.useMemo(() => fundraiser?.logo?.downloadUrl ?? undefined, [fundraiser]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Fragment>
      {loading ? (
        <Skeleton variant="circular" width={CARD_SIZE} height={CARD_SIZE} />
      ) : (
        <ThanksPage title={name} subtitle={['YOU HAVE SUCCESSFULLY DONATED TO']} uri={image} />
      )}
    </Fragment>
  );
}

import { Container, Typography, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CompanyAvatar, Modal } from '@vizsla/components';

const useStyles = makeStyles((theme: Theme) => ({
  titleStyle: {
    fontSize: theme.typography.h3.fontSize || '28px',
    textAlign: 'center',
    margin: theme.spacing(1, 0),
  },
  teamStyle: {
    color: theme.palette.text.secondary || 'GrayText',
    textAlign: 'center',
    margin: theme.spacing(0, 0, 3.5),
  },
  containerStyle: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(1),
  },
}));

export const MembersRankedModal = ({ open, closeModal, title, team, render }) => {
  const classes = useStyles();
  return (
    <Modal fullWidth isOpen={open} onClose={closeModal}>
      <Container>
        <Grid className={classes.containerStyle}>
          <CompanyAvatar
            name={team.name || ''}
            size="80px"
            fontSize="25px"
            avatar={(team?.avatar || undefined) as File}
          />
        </Grid>

        <Typography variant="h1" className={classes.titleStyle}>
          {title}
        </Typography>
        <Typography className={classes.teamStyle} variant="subtitle2">
          {team.name}
        </Typography>
        {render}
      </Container>
    </Modal>
  );
};

import React, { useContext } from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { LandingStateContext } from '../providers/campaign/ThankYouLandingContext';

// import { LandingStateContext } from '@vizsla/providers/src/LandingStateProvider';

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    fontSize: '18px',
  },
  title: {
    fontSize: '26px',
  },
}));

export const MessageThankYouLanding = ({ thankYouMessage, isThankYouMessageVisible }) => {
  const {
    state: { theme },
  } = useContext(LandingStateContext);
  const { accentColor } = theme;

  const classes = useStyles({ accentColor });

  return (
    <React.Fragment>
      {isThankYouMessageVisible && (
        <Grid>
          <Typography
            variant="body1"
            textAlign="center"
            marginBottom={5}
            className={classes.subtitle}
          >
            {thankYouMessage || 'Thank you message for the donor'}
          </Typography>
        </Grid>
      )}
    </React.Fragment>
  );
};

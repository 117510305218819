import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DateTime } from 'luxon';

import { useModal } from '@vizsla/hooks';
import { AsyncContent, HtmlRenderer, Modal } from '@vizsla/components';
import { RegistrationOption, TicketingOption } from '@vizsla/graphql';
import { encodedHtmlIsEmpty, formatDateIntl, formatMoney } from '@vizsla/utils';

import { MODALS } from '../../../constants/modals';
import { GridAutoColumns, GridItem } from './ExperienceOptionModal.style';

export function ExperienceOptionModal() {
  const { isOpen, closeModal, args } = useModal(MODALS.REGISTRATION_DETAILS_MODAL);
  const [loading, setLoading] = useState(true);
  const option = React.useMemo(() => {
    if (args) {
      setLoading(false);
      return args;
    }
    return {};
  }, [args]);
  const registrationOption = React.useMemo(() => {
    if (args) {
      if (args.name) {
        return { ...args, __typename: 'RegistrationOption' } as RegistrationOption;
      }
      return { ...args, __typename: 'TicketingOption' } as TicketingOption;
    }
    return undefined;
  }, [args]);

  const registrationAvailability = React.useMemo(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (registrationOption?.__typename === 'RegistrationOption') {
      const { registrationStartDate, registrationEndDate } = registrationOption ?? {};
      if (!registrationStartDate || !registrationEndDate) return ['Unlimited'];
      return [registrationStartDate, registrationEndDate].map(date =>
        formatDateIntl(date, DateTime.DATETIME_MED),
      );
    }
    // eslint-disable-next-line no-underscore-dangle
    if (registrationOption?.__typename === 'TicketingOption') {
      const { ticketingAvailabilityStartDateTime, ticketingAvailabilityEndDateTime } =
        registrationOption ?? {};
      if (!ticketingAvailabilityStartDateTime || !ticketingAvailabilityEndDateTime)
        return ['Unlimited'];
      return [ticketingAvailabilityStartDateTime, ticketingAvailabilityEndDateTime].map(date =>
        formatDateIntl(date, DateTime.DATETIME_MED),
      );
    }
    return [];
  }, [registrationOption, args]);

  const minimumFundraising = React.useMemo(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (registrationOption?.__typename === 'RegistrationOption') {
      const { fundraisingMinimum = 0 } = registrationOption ?? {};
      if (fundraisingMinimum === 0) return 'Unavailable';
      return formatMoney(fundraisingMinimum);
    }
    return undefined;
  }, [registrationOption]);

  const description = React.useMemo(() => {
    if (!registrationOption?.description || encodedHtmlIsEmpty(registrationOption?.description))
      return 'Description unavailable';
    return registrationOption?.description;
  }, [registrationOption]);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} fullWidth DialogProps={{ maxWidth: 'sm' }}>
      <Modal.Title>
        <Box textAlign="center">{option && option?.name ? option?.name : option?.ticketName}</Box>
      </Modal.Title>
      <Modal.Content>
        <AsyncContent loading={loading}>
          <GridAutoColumns>
            <GridItem>
              <Typography variant="subtitle2">Registration availability</Typography>
              {registrationAvailability.map(date => (
                <Typography key={date} variant="caption">
                  {date}
                </Typography>
              ))}
            </GridItem>
            {option && option?.name && (
              <GridItem>
                <Typography variant="subtitle2">Minimum fundraising</Typography>
                <Typography variant="caption">{minimumFundraising}</Typography>
              </GridItem>
            )}
          </GridAutoColumns>
          <Box mt={3}>
            <HtmlRenderer html={description} />
          </Box>
        </AsyncContent>
      </Modal.Content>
    </Modal>
  );
}

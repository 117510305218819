import React, { useEffect, useState } from 'react';
import { Typography, FormControl, TextField } from '@mui/material';

import { Modal } from '@vizsla/components';
import { useModal, useNotification } from '@vizsla/hooks';

import { MODALS } from 'src/constants/modals';
import { useEarlyBirdVerification } from 'src/hooks';

export const EarlyBird = () => {
  const { loading, verifyEarlyBirdCode } = useEarlyBirdVerification();
  const { isOpen, closeModal, args } = useModal(MODALS.EARLY_BIRD);
  const notification = useNotification();
  const [code, setCode] = useState('');
  const [experienceId, setExperienceId] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  useEffect(() => {
    if (args?.experienceId) setExperienceId(args?.experienceId);
  }, [args]);

  const handleVerifyCode = async () => {
    const valid = await verifyEarlyBirdCode(code, experienceId);
    if (valid) {
      args.handleChangeAccess(true);
      notification.success('Valid Code');
      handleCloseModal();
      return;
    }
    notification.error('Invalid Code');
  };

  const handleCloseModal = () => {
    setCode('');
    setExperienceId('');
    closeModal();
  };

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} onClose={handleCloseModal} fullWidth>
        <Modal.Title>
          <Typography textAlign="center" variant="h2">
            Early Bird
          </Typography>
        </Modal.Title>
        <Modal.Content>
          <FormControl fullWidth>
            <TextField id="EarlyBird" label="Early Bird Code" onChange={handleChange} />
            <Modal.Actions>
              <Modal.Action type="secondary" content="Cancel" onAction={handleCloseModal} />
              <Modal.Action
                type="primary"
                content="Continue"
                onAction={handleVerifyCode}
                loading={loading}
                disabled={!code}
              />
            </Modal.Actions>
          </FormControl>
        </Modal.Content>
      </Modal>
    </React.Fragment>
  );
};

import * as yup from 'yup';
import _ from 'lodash';

import { validateWithSchema, ValidationObject } from '@vizsla/utils';
import {
  CampaignQuestionDisplaySettings,
  CampaignQuestionInputType,
  CampaignQuestionFormat,
} from '@vizsla/types';
import { CreditCardValidationSchema } from '@vizsla/stripe';
import { BillingAddressValidationSchema, PhoneSchema } from '@vizsla/constants';

const getYupSchemaByQuestionInputType = (
  type: CampaignQuestionInputType,
  format: CampaignQuestionFormat,
): yup.BaseSchema => {
  switch (type) {
    case CampaignQuestionInputType.text:
    case CampaignQuestionInputType.date: {
      switch (format) {
        case CampaignQuestionFormat.email: {
          return yup.string().nullable().email();
        }

        case CampaignQuestionFormat.number: {
          return yup.number().nullable();
        }

        case CampaignQuestionFormat.phone: {
          return PhoneSchema.fields.number;
        }
        default:
          return yup.string().nullable();
      }
    }

    case CampaignQuestionInputType.checkbox: {
      return yup.boolean().nullable();
    }

    case CampaignQuestionInputType.radio:
    case CampaignQuestionInputType.select: {
      const schema = yup.string().nullable();
      if (format === CampaignQuestionFormat.multipleChoice) {
        return yup.array(schema).nullable();
      }
      return schema;
    }

    default: {
      return yup.mixed().nullable();
    }
  }
};

const getQuestionYupSchema = ({
  name,
  type: questionInputType,
  format,
  required,
}: CampaignQuestionDisplaySettings) => {
  const yupSchema = getYupSchemaByQuestionInputType(questionInputType, format);

  return yup.object({
    [name]: required ? yupSchema.required('Required') : yupSchema,
  });
};

type QuestionValidator = (
  value: string | null | undefined,
) => Promise<ValidationObject | undefined>;

export const getCustomQuestionValidator = (
  settings: CampaignQuestionDisplaySettings,
): QuestionValidator => {
  const schema = getQuestionYupSchema(settings);

  return async value => {
    const error = await validateWithSchema(schema, { [settings.name]: value });
    return !_.isEmpty(error) ? error : undefined;
  };
};

export const AttendeeRegistrationCheckoutStepSchema = yup.object({
  creditCard: CreditCardValidationSchema,
  billingAddress: BillingAddressValidationSchema,
});

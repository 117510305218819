import type { PublicRouteProps } from '@vizsla/types';
import { LANDING_ROUTES } from '@vizsla/constants';

import { ROUTES } from 'src/constants';

import { TeamLandingPage } from './TeamLandingPage';
import { ExperienceLandingPage } from './ExperienceLandingPage';
import { FundraiserLandingPage } from './FundraiserLandingPage';
import { TeamFundraisingDonate } from './TeamFundraisingDonate';
import { TeamFundraisingDonationThanks } from './TeamFundraisingDonationThanks';
import { FundraiserDonationThanks } from './FundraiserDonationThanks';
import { ExperienceThanks } from './ExperienceThanks';
import { FundraiserDonation } from './FundraiserDonation';
import { CheckoutPage, CheckoutPageOnePage } from './CheckoutPage';
import { AddPaymentMethodStep } from './AddPaymentMethod';
import { ExperienceOptionsStep } from './ExperienceOptionsStep';
import { ExperienceAttendeesStep } from './ExperienceAttendeesStep';
import { ExperienceTeamStep } from './ExperienceTeamStep';
import { ExperienceAssetsStep } from './ExperienceAssetsStep';
import { ExperienceSwagBagStep } from './ExperienceSwagBagStep';
import { ExperienceDonationStep } from './ExperienceDonationStep';

export const PUBLIC_ROUTES: Array<PublicRouteProps> = [
  {
    path: LANDING_ROUTES.TEAM,
    element: TeamLandingPage,
    isPrivate: false,
  },
  {
    path: LANDING_ROUTES.EXPERIENCE,
    element: ExperienceLandingPage,
    isPrivate: false,
  },
  {
    path: LANDING_ROUTES.CAMPAIGN,
    element: ExperienceLandingPage,
    isPrivate: false,
  },
  {
    path: LANDING_ROUTES.FUNDRAISER,
    element: FundraiserLandingPage,
    isPrivate: false,
  },
];

export const PRIVATE_ROUTES: Array<PublicRouteProps> = [
  {
    path: ROUTES.CHECKOUT,
    element: CheckoutPage,
    isPrivate: true,
  },
  {
    path: LANDING_ROUTES.EXPERIENCE_PAYMENT,
    element: AddPaymentMethodStep,
    isPrivate: true,
  },

  /// Experience

  {
    path: LANDING_ROUTES.EXPERIENCE_REGISTRATION,
    element: ExperienceOptionsStep,
    isPrivate: true,
  },

  {
    path: LANDING_ROUTES.EXPERIENCE_ATTENDEES,
    element: ExperienceAttendeesStep,
    isPrivate: true,
  },

  {
    path: LANDING_ROUTES.EXPERIENCE_TEAM,
    element: ExperienceTeamStep,
    isPrivate: true,
  },

  {
    path: LANDING_ROUTES.EXPERIENCE_SWAGBAGS,
    element: ExperienceSwagBagStep,
    isPrivate: true,
  },

  {
    path: LANDING_ROUTES.EXPERIENCE_ASSETS,
    element: ExperienceAssetsStep,
    isPrivate: true,
  },

  {
    path: LANDING_ROUTES.EXPERIENCE_DONATION,
    element: ExperienceDonationStep,
    isPrivate: true,
  },

  {
    path: LANDING_ROUTES.EXPERIENCE_THANKS,
    element: ExperienceThanks,
    isPrivate: true,
  },

  {
    path: LANDING_ROUTES.FUNDRAISER_DONATION,
    element: FundraiserDonation,
    isPrivate: true,
  },

  {
    path: LANDING_ROUTES.FUNDRAISER_DONATION_THANKS,
    element: FundraiserDonationThanks,
    isPrivate: true,
  },

  {
    path: LANDING_ROUTES.TEAM_DONATION,
    element: TeamFundraisingDonate,
    isPrivate: true,
  },

  {
    path: LANDING_ROUTES.TEAM_DONATION_THANKS,
    element: TeamFundraisingDonationThanks,
    isPrivate: true,
  },
  {
    path: LANDING_ROUTES.EXPERIENCE_BILLING_ADDRESS,
    element: CheckoutPageOnePage,
    isPrivate: true,
  },
];

export const ALL_ROUTES: Array<PublicRouteProps> = [...PUBLIC_ROUTES, ...PRIVATE_ROUTES];

import { create } from 'zustand';

import { Question } from '@vizsla/types';

interface CustomQuestionsState {
  data: Question[];
  loading: boolean;
  error: any;
}
export const useCustomQuestionsState = create<CustomQuestionsState>(() => ({
  data: [],
  loading: true,
  error: null,
}));
export const useDefaultQuestionsState = create<CustomQuestionsState>(() => ({
  data: [],
  loading: true,
  error: null,
}));

import { useCallback, useMemo } from 'react';
import { isNil } from 'lodash';

import { CartItem } from '@vizsla/types';
import { validateWithSchema } from '@vizsla/utils';
import {
  ShoppingCartRegistrationData,
  selectAllRegistrations,
  useShoppingCartState,
} from '@vizsla/hooks';

import { useSelectedExperience } from '../experience';
import { buildExperienceRegistrationSchema } from '../../constants/validationSchemas/experienceRegistration';
import { useCustomQuestionsState, useDefaultQuestionsState } from '../questions';
import { useAvailableRegistrationOptions } from './useAvailableRegistrationOptions';

export function useRegistrationOptionsValidationSchema(
  registrationActive: CartItem<ShoppingCartRegistrationData>,
) {
  const { data: currentExperience } = useSelectedExperience();

  const { data: experiences } = useAvailableRegistrationOptions({
    filter: { experience: { id: { equals: currentExperience?.id } } },
    skip: !currentExperience?.id,
  });

  const waiverIsEnabled =
    (currentExperience?.waiverEnabled && !isNil(currentExperience.waiverTextBody)) || false;

  const registrations = useShoppingCartState(selectAllRegistrations);
  const defaultQuestions = useDefaultQuestionsState(state => state.data);
  const customQuestions = useCustomQuestionsState(state => state.data);

  const getActiveExperience =
    experiences?.filter(({ id }) => id === registrationActive?.option?.id)[0] || null;

  const getAgeRequirementById = getActiveExperience?.ageRequirement || null;
  const getDateForAgeRequirementById = getActiveExperience?.ageRequirementAsOfDate || null;

  const validationSchema = useMemo(() => {
    return buildExperienceRegistrationSchema({
      waiverEnabled: waiverIsEnabled,
      countAttendees: registrations.length,
      customQuestions,
      defaultQuestions,
      ageRequirement: getAgeRequirementById,
      validAgeFrom: getDateForAgeRequirementById,
    });
  }, [
    waiverIsEnabled,
    registrations.length,
    customQuestions,
    defaultQuestions,
    getAgeRequirementById,
    getDateForAgeRequirementById,
  ]);

  const validateForm = useCallback(
    async (values: any) => {
      //
      const errors: any = await validateWithSchema(validationSchema, values);
      // Validate email
      const emails = values.attendees.map(a => a.email);
      const uniqueEmails = new Set(emails);
      if (emails.length !== uniqueEmails.size) {
        errors.attendees = errors.attendees ?? [];
        emails.forEach((email, index) => {
          if (emails.indexOf(email) !== index) {
            errors.attendees[index] = errors.attendees[index] ?? {};
            errors.attendees[index].email = 'Email is already in use';
          }
        });
      }
      return errors;
    },
    [validationSchema],
  );

  return { validateForm, validationSchema };
}

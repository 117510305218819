import { create } from 'zustand';

interface WaiverData {
  waiverSignature: string;
  waiverAcceptance: boolean;
}
interface State {
  data?: WaiverData;
  loading: boolean;
}

const INITIAL_STATE: State = { data: undefined, loading: true };

interface Waiver extends State {
  setData(data: WaiverData): void;
  setLoading(loading: boolean): void;
  clean(): void;
}

export const useSelectedWaiver = create<Waiver>(set => ({
  ...INITIAL_STATE,

  setData(data) {
    set({ data, loading: false });
  },

  setLoading(loading) {
    set({ loading });
  },

  clean() {
    set(INITIAL_STATE);
  },
}));

import { useSubdomain } from '@vizsla/hooks';
import { usePublicClientDnsRecordBySlugQuery } from '@vizsla/graphql';

export const useCurrentOrganization = () => {
  const subdomain = useSubdomain();

  const { data, loading } = usePublicClientDnsRecordBySlugQuery({
    variables: {
      slug: subdomain as string,
    },
    skip: !subdomain,
  });

  const organization = data?.dnsRecord?.campaign?.vizslaOrganization;

  return { organization, loading };
};

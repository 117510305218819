import { useMemo } from 'react';

import { useAttendeeFundraiserLandingQuery } from '@vizsla/graphql';
import { calcPercentage } from '@vizsla/utils';

import {
  CampaignLandingItem,
  ExperienceLandingItem,
  FundraiserLandingItem,
  FundraisingLandingItem,
  TeamLandingItem,
  UserLandingItem,
} from './types';

interface FundraiserLandingData {
  attendee?: FundraiserLandingItem;
  campaign?: CampaignLandingItem;
  experience?: ExperienceLandingItem;
  fundraiser?: FundraisingLandingItem;
  fundraiserPercentage: number;
  loading: boolean;
  team?: TeamLandingItem;
  user?: UserLandingItem;
}

export function useFundraiserLandingData(idOrSlug?: string): FundraiserLandingData {
  const { data: response, loading } = useAttendeeFundraiserLandingQuery({
    skip: !idOrSlug,
    variables: { idOrSlug: idOrSlug as string },
  });

  const [landing] = response?.fundraiserLandingData?.items ?? [];

  const raised = landing?.fundraising?.raised ?? landing?.totalRaised ?? 0;
  const goal = landing?.fundraising?.goal ?? landing?.fundraiserGoal ?? 0;

  const percentage = useMemo(() => calcPercentage(raised, goal), [raised, goal]);

  const payload: FundraiserLandingData = {
    attendee: landing as FundraiserLandingItem,
    campaign: landing?.experience?.campaign as CampaignLandingItem,
    experience: landing?.experience as ExperienceLandingItem,
    fundraiser: landing?.fundraising as FundraisingLandingItem,
    fundraiserPercentage: percentage >= 100 ? 100 : percentage,
    team: landing?.teamMembership?.team as TeamLandingItem,
    user: landing?.user as UserLandingItem,
    loading,
  };

  return payload;
}

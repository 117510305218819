import { useEffect } from 'react';

import { useGetUserByEmailQuery } from '@vizsla/graphql';
import { CartItem, ShoppingCartRegistrationData } from '@vizsla/types';

type AttendeeInfo = {
  activeAttendeeIndex: number;
  formReference: React.RefObject<{
    values: { attendees: Array<any> };
    form: { change: (field: string, value: any) => void };
  }>;
  registrations: CartItem<ShoppingCartRegistrationData>[];
};

// Custom hook to autofill attendee information based on the email provided.
export const useAutoFillAttendeeInfo = ({
  activeAttendeeIndex,
  formReference,
  registrations,
}: AttendeeInfo) => {
  const { refetch: fetchEmailByQuery } = useGetUserByEmailQuery({
    skip: true,
  });
  useEffect(() => {
    // Selects the email input element for the active attendee.
    const inputElement = document.querySelector(
      `input[name="attendees[${activeAttendeeIndex}]email"]`,
    );
    // Handler to fetch and autofill user data on input blur.
    const handleBlur = async event => {
      const email = event.target.value;
      if (email) {
        try {
          const { data: dataUser } = await fetchEmailByQuery({ email });
          const attendee = formReference?.current?.values?.attendees?.[activeAttendeeIndex];
          if (attendee && dataUser?.user) {
            const {
              address = {},
              firstName,
              lastName,
              phone = {},
              gender,
              birthDate,
            } = dataUser?.user ?? {};

            // Function to update form values for the attendee.
            const updateFormValues = (attendeeIndex: number, newValues: Record<string, any>) => {
              // Filter out fields that are null or undefined
              const filteredValues = Object.fromEntries(
                Object.entries(newValues).filter(
                  ([, value]) => value !== null && value !== undefined && value !== '',
                ),
              );

              // Update the form with the filtered values
              Object.entries(filteredValues).forEach(([key, value]) => {
                formReference?.current?.form?.change(`attendees[${attendeeIndex}].${key}`, value);
              });
            };
            updateFormValues(activeAttendeeIndex, {
              address: address?.street1 ?? '',
              firstName,
              lastName,
              phone: `${phone?.number ?? ''}`,
              gender,
              birthday: birthDate,
              state: address?.state ?? '',
              zip: address?.zip ?? '',
              city: address?.city ?? '',
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    if (inputElement) {
      inputElement.addEventListener('blur', handleBlur);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('blur', handleBlur);
      }
    };
  }, [activeAttendeeIndex, fetchEmailByQuery, formReference, registrations]);
};

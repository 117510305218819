export function areAllValuesEmpty(obj) {
  if (obj === null) return true;

  return Object.values(obj).every(value => {
    if (typeof value === 'object' && value !== null) {
      return areAllValuesEmpty(value);
    }
    return value === '';
  });
}

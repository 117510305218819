import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 800px;
`;

export const MessageContainer = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 10px;
  text-align: center;
`;

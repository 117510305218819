import { create } from 'zustand';
import { useParams } from 'react-router-dom';

import { TeamFundraising, useTeamFundraisingManagementQuery } from '@vizsla/graphql';

interface SelectedTeamFundraisingStore {
  loading: boolean;
  data?: TeamFundraising;

  setLoading(loading: boolean): void;

  setData(data: TeamFundraising): void;

  clean(): void;
}

export const useSelectedTeamFundraiserStore = create<SelectedTeamFundraisingStore>(set => ({
  loading: true,
  data: undefined,
  setLoading: (loading: boolean) => set({ loading }),
  setData: (data: TeamFundraising) => set({ data, loading: false }),
  clean: () => set({ data: undefined, loading: false }),
}));

export function useSelectedTeamFundraiser() {
  const { teamId } = useParams<{ teamId: string }>();
  const { data, setData } = useSelectedTeamFundraiserStore();

  const { loading } = useTeamFundraisingManagementQuery({
    skip: !teamId,
    variables: { id: teamId as string },
    onCompleted: ({ teamFundraising }) => {
      setData(teamFundraising as TeamFundraising);
    },
  });

  return { data, loading };
}

import React, { ReactNode } from 'react';
import { ListItemIcon, ListItemIconProps } from '@mui/material';

interface CustomListItemIconProps extends ListItemIconProps {
  icon?: ReactNode;
}

export const CustomListItemIcon: React.FC<CustomListItemIconProps> = symbolProps => {
  return (
    <ListItemIcon
      sx={{
        minWidth: 'auto',
        color: 'inherit',
      }}
      onClick={symbolProps?.onClick}
      onMouseOver={symbolProps?.onMouseOver}
      onMouseOut={symbolProps?.onMouseOut}
      onMouseDown={symbolProps?.onMouseDown}
      onMouseUp={symbolProps?.onMouseUp}
      onMouseEnter={symbolProps?.onMouseEnter}
      onMouseLeave={symbolProps?.onMouseLeave}
      onWheel={symbolProps?.onWheel}
      onContextMenu={symbolProps?.onContextMenu}
      onAuxClick={symbolProps?.onAuxClick}
    >
      {symbolProps.icon}
    </ListItemIcon>
  );
};

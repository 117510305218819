import Skeleton from '@mui/material/Skeleton';
import React from 'react';

import { Container } from './RegistrationsOptionForm.styles';

export const RegistrationsOptionFormSkeleton: React.FC = () => (
  <Container>
    <Skeleton height={80} variant="rectangular" />
    <Skeleton height={80} variant="rectangular" />
    <Skeleton height={80} variant="rectangular" />
  </Container>
);

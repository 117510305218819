import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { LANDING_ROUTES } from '@vizsla/constants';
import { useExperienceId } from '@vizsla/hooks';
import { buildUrl } from '@vizsla/utils';

import { useSelectedExperience } from './selectedExperience';

export function useOpenExperiencePages() {
  const navigate = useNavigate();

  const { data: experience } = useSelectedExperience();

  const routeId = useExperienceId();

  const selectedId = useMemo(() => experience?.id ?? undefined, [experience]);
  const fallbackId = useMemo(() => routeId ?? selectedId, [routeId, selectedId]);

  const open = (route: string, params: Record<string, any>) => {
    const destination = buildUrl(route, params);
    navigate(destination);
  };

  return {
    open,

    openLanding(experienceId = fallbackId) {
      open(LANDING_ROUTES.EXPERIENCE, { experienceId });
    },

    openRegistrationForm(experienceId = fallbackId) {
      open(LANDING_ROUTES.EXPERIENCE_REGISTRATION, { experienceId });
    },

    openAttendeesForm(experienceId = fallbackId) {
      open(LANDING_ROUTES.EXPERIENCE_ATTENDEES, { experienceId });
    },

    openSelectTeam(experienceId = fallbackId) {
      open(LANDING_ROUTES.EXPERIENCE_TEAM, { experienceId });
    },

    openSwagBagsForm(experienceId = fallbackId) {
      open(LANDING_ROUTES.EXPERIENCE_SWAGBAGS, { experienceId });
    },

    openAssetsForm(experienceId = fallbackId) {
      open(LANDING_ROUTES.EXPERIENCE_ASSETS, { experienceId });
    },

    openDonationForm(experienceId = fallbackId) {
      open(LANDING_ROUTES.EXPERIENCE_DONATION, { experienceId });
    },

    openBillingAddress(experienceId = fallbackId) {
      open(LANDING_ROUTES.EXPERIENCE_BILLING_ADDRESS, { experienceId });
    },

    openPayment(experienceId = fallbackId) {
      open(LANDING_ROUTES.EXPERIENCE_PAYMENT, { experienceId });
    },

    openThanks(experienceId = fallbackId) {
      open(LANDING_ROUTES.EXPERIENCE_THANKS, { experienceId });
    },
  };
}

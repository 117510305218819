import { useState } from 'react';
import { Grid, Link, typographyClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CompanyAvatarFullNameInline, TableColumn } from '@vizsla/components';
import { CampaignTeamMember, Team_FundraiserLandingItem } from '@vizsla/graphql';
import { formatMoney, getUserFullName } from '@vizsla/utils';
import { PaletteColor } from '@vizsla/theme';
import { useMediaBreakpoints } from '@vizsla/hooks';

import { MEMBERS, TEAM_RANKED } from '../constants/raisedTypes';

const useStyles = makeStyles({
  subTitle: {
    [`&.${typographyClasses.root}`]: {
      color: PaletteColor.GrayText,
    },
  },
  tableRowAction: {
    [`&.${typographyClasses.root}`]: {
      color: PaletteColor.DefaultText,
    },
  },
  circle: {
    width: '24px',
    height: '24px',
    backgroundColor: '#000',
    color: '#fff',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    marginLeft: '-7px',
  },
});

interface UseRaisedTableHook {
  getColumnConfiguration: (
    type: string,
    team: Team_FundraiserLandingItem,
  ) => TableColumn<CampaignTeamMember>[];
}

export const useRaisedTable = (): UseRaisedTableHook => {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);
  const { isSm } = useMediaBreakpoints();
  const createUrl = (id: string): string => {
    const host = window.location.host;
    const protocol = window.location.hostname.includes('localhost') ? 'http' : 'https';
    return `${protocol}://${host}/team/${id}`;
  };

  const getColumnConfiguration = (
    type: string,
    team: Team_FundraiserLandingItem,
  ): TableColumn<CampaignTeamMember>[] => {
    switch (type) {
      case MEMBERS:
        return [
          {
            key: 'rank',
            sortable: true,
            title: 'ranked',
            dataPath: 'rank',
          },
          {
            key: 'attendee.user.firstName',
            sortable: true,
            title: 'user',
            render: (member: CampaignTeamMember) => {
              return (
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    borderColor: '#000',
                  }}
                >
                  <Grid item>
                    <CompanyAvatarFullNameInline
                      name={getUserFullName(member.attendee?.user) || ''}
                      avatar={(member.attendee?.user?.avatar || undefined) as File}
                      showAvatar={!isSm}
                    />
                  </Grid>
                </Grid>
              );
            },
          },
          {
            key: 'totalRaised',
            sortable: true,
            title: 'raised',
            render: item => {
              return formatMoney(item?.totalRaised ?? 0, 2);
            },
          },
        ];

      case TEAM_RANKED:
        return [
          {
            key: 'rank',
            sortable: true,
            title: 'ranked',
            render: item => {
              if (team.id === item.id) {
                return <div className={classes.circle}>{item.rank}</div>;
              }
              return item.rank;
            },
          },
          {
            key: 'name',
            sortable: true,
            title: 'user',
            render: member => {
              return (
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    borderColor: '#000',
                  }}
                >
                  <Grid item>
                    <CompanyAvatarFullNameInline
                      name={member.name || ''}
                      avatar={(member.avatar || undefined) as any}
                      showAvatar={!isSm}
                    />
                  </Grid>
                </Grid>
              );
            },
          },
          {
            key: 'totalRaised',
            sortable: true,
            title: 'raised',
            render: item => {
              return (
                <div
                  onMouseEnter={() => setIsHovered(item.id)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  {isHovered === item.id ? (
                    <Link
                      href={createUrl(item.id)}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      View Team Page
                    </Link>
                  ) : (
                    formatMoney(item?.totalRaised ?? 0, 2)
                  )}
                </div>
              );
            },
          },
        ];
      default:
        return [];
    }
  };

  return { getColumnConfiguration };
};

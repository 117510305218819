import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { ApolloQueryResult } from '@apollo/client';

import { useCurrentUser, useNotification } from '@vizsla/hooks';
import {
  StripePaymentMethodsUserQuery,
  useStripeOverwritePaymentMethodMutation,
} from '@vizsla/graphql';

import { usePaymentMethodSetup } from 'src/hooks/stripe';
import { StripeProvider } from 'src/providers/StripeProvider';

import { PaymentMethodForm, Values } from './PaymentMethodForm';
import { Container, LoaderContainer, Title } from './CreatePaymentMethod.styles';

interface SavePaymentProps {
  setOtherMethod: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => void;
  existPaymentMethod: boolean;
  setDataCreditCartInit: React.Dispatch<React.SetStateAction<any | null>>;
  validatePaymentData: boolean;
  setValidatePaymentData(value: boolean): void;
  setCompletePayment(value: boolean): void;
}
export const SavePaymentMethod = (props: SavePaymentProps) => {
  const notification = useNotification();

  const [fetching, setFetching] = useState(false);
  const [isCheckedCreditCard, setIsCheckedCreditCard] = useState(false);
  const [stripeSecret, setStripeSecret] = useState<string>();
  const [customerId, setCustomerId] = useState('');

  const { user } = useCurrentUser();
  const userId = React.useMemo(() => user?.id, [user]);
  const { execute: createSetup } = usePaymentMethodSetup();

  const [stripeOverwritePaymentMethodMutation] = useStripeOverwritePaymentMethodMutation();

  const initialize = async () => {
    if (!userId || Boolean(stripeSecret)) return;

    setFetching(true);

    try {
      const setup = await createSetup({ userId });
      setStripeSecret(setup.secret);
      setCustomerId(setup.customerId);
      setFetching(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!stripeSecret) {
      initialize();
    }

    return () => {
      setStripeSecret(undefined);
    };
  }, []);

  const handleChangeCreditCard = event => {
    const value = event.target.checked as boolean;
    setIsCheckedCreditCard(value);
  };

  const onCancel = () => {
    props.setOtherMethod(false);
  };

  const handleSubmit = async (values: Values, stripe: Stripe, elements: StripeElements) => {
    if (props.existPaymentMethod) {
      // process for add payment methods when the user alreagy has one
      try {
        if (isCheckedCreditCard) {
          const stripeResponse = await stripeOverwritePaymentMethodMutation({
            variables: {
              customerId,
            },
          });

          if (!stripeResponse.data?.stripeRemovePaymentMethod?.success) {
            throw new Error(stripeResponse.data?.stripeRemovePaymentMethod?.error ?? '');
          }
        }
        const response = await stripe.confirmSetup({
          elements,
          redirect: 'if_required',
        });
        props.setOtherMethod(false);
        props.refetch();

        if (response.error) {
          throw new Error('Something went wrong on adding a new payment method');
        }

        /* Add method to update and remove the last one */
      } catch (error) {
        console.error(error);

        notification.error(error?.message ?? '');
      }
    } else {
      // procces for add teh firts payment method
      props.setDataCreditCartInit({
        stripe,
        elements,
      });
    }
  };
  return (
    <React.Fragment>
      {fetching || !stripeSecret ? (
        <React.Fragment>
          <LoaderContainer>
            <CircularProgress />
          </LoaderContainer>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Container>
            <StripeProvider clientSecret={stripeSecret}>
              <PaymentMethodForm
                labelSubmit="Add new payment method"
                onSubmit={handleSubmit}
                onCancel={onCancel}
                existPaymentMethod={props.existPaymentMethod}
                validatePaymentData={props.validatePaymentData}
                setValidatePaymentData={props.setValidatePaymentData}
                setCompletePayment={props.setCompletePayment}
                isCheckedCreditCard={isCheckedCreditCard}
                handleChangeCreditCard={handleChangeCreditCard}
              />
            </StripeProvider>
          </Container>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

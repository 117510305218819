import { ApolloError } from '@apollo/client';

import { User, usePublicClientUserQuery } from '@vizsla/graphql';
import { getUserFullName } from '@vizsla/utils';

type UserCurrentUserHook = {
  user: User;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  fullName: string;
  loading: boolean;
  error: ApolloError | undefined;
};

const useCurrentUser = (): UserCurrentUserHook => {
  const { data, loading, error } = usePublicClientUserQuery();
  const user = data?.user as User;

  return {
    user,
    firstName: user?.firstName,
    lastName: user?.lastName,
    fullName: getUserFullName(user),
    loading,
    error,
  };
};

export { useCurrentUser };

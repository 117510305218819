import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { DonationFormValues } from '@vizsla/components';
import { useTeamFundraisingLazyQuery } from '@vizsla/graphql';
import {
  selectAllDonations,
  useCurrentUser,
  useNotification,
  useShoppingCartState,
} from '@vizsla/hooks';
import { validateWithSchema, formatDate } from '@vizsla/utils';
import { DateFormatPatterns } from '@vizsla/constants';
import { IntegrationType, DefaultIntegrationCredentialsType } from '@vizsla/types';

import { DONATION_FORM_SCHEMA } from 'src/constants/validationSchemas';
import { useSelectedTeamFundraising, useOpenExperiencePages } from 'src/hooks';
import { useEventStoreContext } from 'src/providers/EventStoreProvider';

export const selectDonationState = state => ({
  donation: selectAllDonations(state).at(0),
  addCart: state.add,
  removeCart: state.remove,
  items: state.items,
});

export function useTeamFundraisingDonate() {
  const { setDonation } = useEventStoreContext();
  const { donation, removeCart, addCart: addToCart } = useShoppingCartState(selectDonationState);
  const {
    data: team,
    loading: fetching,
    setData: setTeam,
    setLoading: setTeamFetching,
  } = useSelectedTeamFundraising();
  const { openBillingAddress } = useOpenExperiencePages();

  const { user } = useCurrentUser();
  const { teamId } = useParams();
  const toaster = useNotification();

  const [executeTeamQuery] = useTeamFundraisingLazyQuery();

  const fetchTeamByParams = useCallback(async () => {
    if (!teamId) return;

    setTeamFetching(true);

    const response = await executeTeamQuery({
      variables: {
        filter: {
          OR: [{ id: { equals: teamId } }, { webLink: { equals: teamId } }],
        },
      },
    });

    const team = response.data?.team?.items?.[0];

    if (team) {
      setTeam(team);
      setTeamFetching(false);
    }
  }, [executeTeamQuery, setTeam, setTeamFetching, teamId]);

  useEffect(() => {
    fetchTeamByParams();
  }, [fetchTeamByParams]);

  const handleSubmit = async (values: DonationFormValues) => {
    setDonation(true);
    const price = values.amount ?? 0;
    const makeAnonymous = values.makeAnonymous ?? false;

    if (!team?.id) {
      toaster.error('Team must be defined');
      return;
    }

    if (!team?.experience?.id) {
      toaster.error('Experience must be defined.');
      return;
    }

    if (!user?.id) {
      toaster.error('User must be defined.');
      return;
    }

    if (donation) removeCart(donation);

    addToCart({
      type: 'donation',
      donor: { id: user.id },
      allocation: { type: 'team', id: team.id },
      experience: { id: team.experience.id },
      message: values.message,
      makeAnonymous,
      price,
      frecuency: values.frecuency,
      questions: values.donorsGroupquestions,
      startDate: formatDate(values.startDate, DateFormatPatterns.shortDateWithDash) ?? undefined,
      endDate: formatDate(values.endDate, DateFormatPatterns.shortDateWithDash) ?? undefined,
      organizationId: team?.experience?.campaign?.vizslaOrganization?.id ?? '',
      integrationType: values.integrationType as IntegrationType,
      integrationCredentials: values.integrationCredentials as DefaultIntegrationCredentialsType,
      integrationCompany: values.integrationCompany as string,
    });

    const experienceId = team.experience.id;
    openBillingAddress(experienceId);
  };

  const validateForm = (values: DonationFormValues) => {
    return validateWithSchema(DONATION_FORM_SCHEMA, values);
  };

  return {
    team,
    fetching,

    validateForm,
    handleSubmit,
  };
}

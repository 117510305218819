import React from 'react';
import { Grid, styled, Typography, Box } from '@mui/material';

import { EMPTY_IMAGE } from 'src/assets';
import { areAllValuesEmpty } from 'src/utils/validation';

const StyledImageGrid = styled(Box)({
  width: '100px',
  height: 'auto',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
});

interface StoreAssetTableItemProps {
  storeItem: any;
}

export const AssetNameCell: React.FC<StoreAssetTableItemProps> = ({ storeItem }) => {
  const imageSrc = storeItem.uri || EMPTY_IMAGE;

  const subNamesLabels = React.useMemo(() => {
    if (!areAllValuesEmpty(storeItem?.variantsValues)) {
      return Object.entries(storeItem?.variantsValues).reduce(
        (acum, item, index) =>
          index === 0 ? `${item[0]}:${item[1]}` : `${acum} ${item[0]} : ${item[1]}`,
        '',
      );
    }
    return null;
  }, [storeItem]);

  return (
    <Grid xs={10} container alignItems="center" gap={2.5}>
      <StyledImageGrid>
        <img
          src={imageSrc}
          alt="Asset"
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
      </StyledImageGrid>

      <Grid item>
        <Typography variant="body2" fontWeight="bolder">
          {storeItem.name}
        </Typography>

        {subNamesLabels && (
          <Typography variant="body2" fontWeight="bolder">
            {subNamesLabels}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

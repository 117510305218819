import React, { useContext } from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { LandingStateContext } from '../providers/campaign/ThankYouLandingContext';
import { useEventStoreContext } from '../../../providers/EventStoreProvider';

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    fontSize: '18px',
  },
  title: {
    fontSize: '23px',
  },
}));

export const QuestionDonation = ({ contactPhone, contactEmail }) => {
  const {
    state: { theme },
  } = useContext(LandingStateContext);
  const { donation } = useEventStoreContext();

  const { accentColor } = theme;
  const isHelpTitleVisible = theme?.isHelpTitleVisible ?? true;
  const isHelpDescriptionVisible = theme?.isHelpDescriptionVisible ?? true;

  const classes = useStyles({ accentColor });

  return (
    <Grid>
      {donation && isHelpTitleVisible && (
        <Typography variant="h4" textAlign="center" marginBottom={1} className={classes.title}>
          Need help or have question about your donation?
        </Typography>
      )}
      {isHelpDescriptionVisible && (
        <Grid container>
          <Grid item xs={12}>
            <Typography>{`If you have any question, contact ${contactEmail} or call ${contactPhone}`}</Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Toolbar, Theme, Paper, IconButton, styled } from '@mui/material';

import { Button } from '@vizsla/components';
import { TemplateType } from '@vizsla/types';
import { HeaderLogoAlignType } from '@vizsla/constants';
import { useModal, useAppAuth, useCurrentPublicCampaign } from '@vizsla/hooks';

import { MODALS } from 'src/constants/modals';
import { useLandingState } from 'src/hooks/landing';
import { useTeamLandingData } from 'src/hooks/team';
import { useFundraiserLandingData } from 'src/hooks/fundraiser';
import LogoutIcon from '@mui/icons-material/Logout';
import { HeaderLogo } from './HeaderLogo';
import { AvatarImage } from './AvatarImage';
import { useCurrentExperience } from 'src/hooks';
import { ProfilePopover } from './ProfilePopover';

interface StylesParams {
  headerBackground: string;
}

const useStyles = makeStyles<Theme, StylesParams>({
  root: {
    gridArea: 'header',
    zIndex: 2,
    position: 'fixed',
    width: '100%',
  },
  personalButton: {
    marginLeft: 'auto',
    marginRight: '0',
  },
});

interface HeaderProps {
  styles: Record<string, any>;
}

type ToolbarProps = {
  headerLogoAlign: HeaderLogoAlignType;
  headerBackground: string;
};

type ExperienceType = {
  experienceHeaderImage: Record<string, unknown>;
  experienceLogoImage: Record<string, unknown>;
  id: string;
  name: string;
  campaign: Record<string, unknown>;
};

type CampaignType = {
  dnsRecord: Record<string, unknown>;
  fundraisingGoal: number;
  id: string;
  image: Record<string, unknown>;
  logo: Record<string, unknown>;
  name: string;
  totalRaised: Record<string, unknown>;
  vizslaOrganization: Record<string, unknown>;
};

const StyledHeaderLogoWrapper = styled('div')(() => ({
  position: 'absolute',
}));

const StyledToolbar = styled(Toolbar, {
  shouldForwardProp: prop => prop !== 'headerLogoAlign' && prop !== 'headerBackground',
})((props: ToolbarProps) => ({
  justifyContent: `${
    props?.headerLogoAlign === HeaderLogoAlignType.left ? 'flex-start' : 'center'
  }`,
  background: `${props?.headerBackground}`,
}));

export const Header: FC<HeaderProps> = ({ styles }) => {
  const { headerBackground } = styles;
  const classes = useStyles({ headerBackground });
  const { logout, isAuthenticated } = useAppAuth();
  const { openModal } = useModal();
  const { templates } = useLandingState();
  const { fundraiserId, teamId } = useParams();
  const teamData = useTeamLandingData(teamId);
  const fundraiseData = useFundraiserLandingData(fundraiserId as string);
  const { campaign: initialCampaign } = useCurrentPublicCampaign();
  const { experience: initialExperience } = useCurrentExperience();

  const [experience, setExperience] = useState<ExperienceType | null>(null);
  const [campaign, setCampaign] = useState<CampaignType | null>(null);

  useEffect(() => {
    if (teamData?.experience || teamData?.campaign) {
      setExperience((teamData?.experience as ExperienceType) ?? initialExperience);
      setCampaign((teamData?.campaign as unknown as CampaignType) ?? initialCampaign);
      return;
    }
    if (fundraiseData?.experience || fundraiseData?.campaign) {
      setExperience((fundraiseData?.experience as unknown as ExperienceType) ?? initialExperience);
      setCampaign((fundraiseData?.campaign as unknown as CampaignType) ?? initialCampaign);
      return;
    }
    setExperience(initialExperience as ExperienceType);
    // TODO: check initialCampaign
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamData, fundraiseData, initialExperience]);

  const onOpen = () =>
    openModal(MODALS.LOGIN_MODAL, {
      experience,
    });

  const experienceLandingTemplate = templates?.find(
    template => template?.templateType?.type === TemplateType.EXPERIENCE,
  );
  const experienceLandingStructure = experienceLandingTemplate?.structure?.appSchema;
  const headerLogoImage =
    experience?.experienceLogoImage ||
    campaign?.logo ||
    initialExperience?.experienceLogoImage ||
    undefined;
  const headerLogoAlign = experienceLandingStructure?.theme?.headerLogoAlign;

  return (
    <Paper className={classes.root} square elevation={1}>
      <StyledToolbar headerLogoAlign={headerLogoAlign} headerBackground={headerBackground}>
        <StyledHeaderLogoWrapper>
          <HeaderLogo logo={headerLogoImage} />
        </StyledHeaderLogoWrapper>

        <div className={classes.personalButton}>
          {!isAuthenticated && <Button onClick={onOpen}>Log In</Button>}

          {isAuthenticated && <ProfilePopover />}
        </div>
      </StyledToolbar>
    </Paper>
  );
};

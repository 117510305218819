import { create } from 'zustand';

import { ExperienceRegistrationFragment } from '@vizsla/graphql';

interface State {
  data?: ExperienceRegistrationFragment;
  loading: boolean;
}

interface Store extends State {
  setData(data: ExperienceRegistrationFragment): void;
  setLoading(loading: boolean): void;
  clean(): void;
}

const INITIAL_STATE: State = { data: undefined, loading: true };

export const useSelectedExperience = create<Store>(set => ({
  ...INITIAL_STATE,

  setData(data) {
    set({ data, loading: false });
  },

  setLoading(loading) {
    set({ loading });
  },

  clean() {
    set(INITIAL_STATE);
  },
}));

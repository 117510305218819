import styled from '@emotion/styled';
import { TabContext as BaseTabContext, TabPanel as BaseTabPanel } from '@mui/lab';

// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

// TabContext

export const TabContext = styled(BaseTabContext)`
  display: flex;
  flex-direction: column;
`;

// TabPanel

export const TabPanel = styled(BaseTabPanel)`
  padding: 0;
`;

import React from 'react';
import { Grid, Tab, tabClasses, Theme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { makeStyles } from '@mui/styles';

import { Attendee, Experience, useAttendeesByExperienceLandingQuery } from '@vizsla/graphql';
import { RecentActivityTable, AttendeesTable } from '@vizsla/components';

interface StylesParams {
  accentColor: string;
}

const useStyles = makeStyles<Theme, StylesParams>(theme => ({
  tabPanel: {
    padding: 0,
  },
  tabList: {
    [`& .${tabClasses.root}`]: {
      color: ({ accentColor }) => accentColor,
    },
  },
}));

enum PeopleSectionTab {
  RecentActivity = 'Recent Activity',
  Attendees = 'Attendees',
}

interface PeopleSectionProps {
  experience: Experience;
  theme: any;
  fundraiserId: string;
}

export const PeopleSection: React.FC<PeopleSectionProps> = ({
  experience,
  theme,
  fundraiserId,
}) => {
  const experienceId = experience?.id;
  const { data: response, loading } = useAttendeesByExperienceLandingQuery({
    skip: !experienceId,
    variables: { id: experienceId as string, skip: 0, first: 10 },
  });

  const data = (response?.experienceLandingPeopleSectionAttendees?.items ?? []) as Attendee[];

  const { accentColor } = theme;

  const classes = useStyles({ accentColor });

  const [tab, setTab] = React.useState<PeopleSectionTab>(PeopleSectionTab.RecentActivity);

  const tabs = [PeopleSectionTab.RecentActivity, PeopleSectionTab.Attendees];

  const handleTabSwitch = (_: React.ChangeEvent<any>, newValue: PeopleSectionTab) =>
    setTab(newValue);

  return (
    <Grid paddingTop={3}>
      <TabContext value={tab}>
        <Grid container justifyContent="center" marginBottom={3}>
          <TabList onChange={handleTabSwitch} className={classes.tabList}>
            {tabs.map((tab: PeopleSectionTab) => (
              <Tab key={tab} label={tab} value={tab} />
            ))}
          </TabList>
        </Grid>

        <TabPanel value={PeopleSectionTab.RecentActivity} className={classes.tabPanel}>
          <RecentActivityTable id={fundraiserId} type="Fundraising" />
        </TabPanel>

        <TabPanel value={PeopleSectionTab.Attendees} className={classes.tabPanel}>
          <AttendeesTable theme={theme} experienceId={experienceId as string} />
        </TabPanel>
      </TabContext>
    </Grid>
  );
};

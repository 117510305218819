import React, { useMemo } from 'react';
import { Typography } from '@mui/material';

import { useModal } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';

export const VerifyUser = () => {
  const { isOpen, closeModal, args } = useModal(MODALS.VERIFY_USER);

  const warning = useMemo(() => {
    return args ? args.warning : '';
  }, [args]);

  const onCloseModal = useMemo(() => {
    return args ? args.verifyUser : closeModal;
  }, [args, closeModal]);

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal}>
      <Modal.Title>Ups!</Modal.Title>
      <Modal.Content>
        <Typography>{warning}</Typography>
      </Modal.Content>
    </Modal>
  );
};

import React from 'react';
import { Grid, InputAdornment, Typography } from '@mui/material';
import { MailOutlined } from '@mui/icons-material';
import KeyIcon from '@mui/icons-material/Key';
import PasswordIcon from '@mui/icons-material/Password';

import { Field, Form, TextField } from '@vizsla/components';
import { ForgotPasswordCodeVerificationValidationSchema } from '@vizsla/constants';
import { PaletteColor } from '@vizsla/theme';
import { validateWithSchema } from '@vizsla/utils';

import { AuthSubmitButton } from 'src/components/auth';

export type ResetPasswordAndVerifyCodeFormValues = {
  code: string;
  password: string;
};

type ResetPasswordAndVerifyCodeFormProps = {
  classes: any;
  email: string;
  onSubmit: (values: ResetPasswordAndVerifyCodeFormValues) => void;
  onResendCode: () => void;
  onBackChangeEmail: () => void;
  onBackToLogin: (reset: any) => void;
};

export const ResetPasswordAndVerifyCodeForm = ({
  classes,
  email,
  onSubmit,
  onResendCode,
  onBackChangeEmail,
  onBackToLogin,
}: ResetPasswordAndVerifyCodeFormProps) => {
  const validate = React.useCallback((values: ResetPasswordAndVerifyCodeFormValues) => {
    return validateWithSchema(ForgotPasswordCodeVerificationValidationSchema, values);
  }, []);

  return (
    <Form onSubmit={onSubmit} initialValues={{ email }} validate={validate}>
      {({ handleSubmit, submitting, pristine, hasValidationErrors, form }) => {
        const isDisabled = submitting || pristine || hasValidationErrors;
        return (
          <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12} paddingLeft={0}>
                <Grid
                  container
                  spacing={0}
                  margin={0}
                  xs={12}
                  justifyContent="center"
                  direction="column"
                >
                  <Grid item marginTop={2} marginBottom={2}>
                    <Typography variant="body1">
                      We have sent you an email with a code to reset your password. Please check
                      your inbox.
                    </Typography>
                  </Grid>
                  <Grid item paddingTop={1}>
                    <Field
                      name="email"
                      label="Email Address"
                      component={TextField}
                      e2e-id="email"
                      InputProps={{
                        placeholder: 'Email Address',
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutlined />
                          </InputAdornment>
                        ),
                      }}
                      className={classes.checkBox}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item paddingTop={2}>
                    <Field
                      name="code"
                      label="Code"
                      component={TextField}
                      e2e-id="code"
                      InputProps={{
                        placeholder: 'Code',
                        startAdornment: (
                          <InputAdornment position="start">
                            <KeyIcon />
                          </InputAdornment>
                        ),
                      }}
                      className={classes.checkBox}
                      fullWidth
                    />
                  </Grid>
                  <Grid item paddingTop={2}>
                    <Field
                      name="password"
                      label="New password"
                      type="password"
                      component={TextField}
                      e2e-id="password"
                      fullWidth
                      InputProps={{
                        placeholder: 'Enter new password',
                        startAdornment: (
                          <InputAdornment position="start">
                            <PasswordIcon />
                          </InputAdornment>
                        ),
                      }}
                      className={classes.checkBox}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} paddingLeft={0} paddingBottom={0}>
                <AuthSubmitButton
                  type="submit"
                  loading={submitting}
                  disabled={isDisabled}
                  e2e-id="submit"
                >
                  Change password
                </AuthSubmitButton>
              </Grid>
              <Grid item xs={12} paddingLeft={0} className={classes.resendButton}>
                <AuthSubmitButton onClick={onResendCode} e2e-id="resend">
                  Resend code
                </AuthSubmitButton>
              </Grid>
              <Grid item xs={12} className={classes.textContainer}>
                <Typography
                  style={{ color: PaletteColor.TealText, cursor: 'pointer' }}
                  component="span"
                  onClick={() => onBackToLogin(form.reset)}
                >
                  Back to login
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.text2ndContainer}>
                <Typography
                  style={{ color: PaletteColor.TealText, cursor: 'pointer' }}
                  component="span"
                  onClick={onBackChangeEmail}
                >
                  Change email
                </Typography>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Form>
  );
};

import React, { Dispatch, FunctionComponent, SetStateAction, useMemo } from 'react';
import { DateTime } from 'luxon';
import { Button } from '@mui/material';
import PersonOffIcon from '@mui/icons-material/PersonOff';

import { useModal } from '@vizsla/hooks';
import { getDateFromISO } from '@vizsla/utils';

import { MODALS } from 'src/constants/modals';

import { EarlyBird } from '../../../modals/EarlyBird/EarlyBird';
import { Container, MessageContainer } from './NotAvailable.styled';

enum InvalidType {
  experienceHasNotStarted = 'experienceHasNotStarted',
  experienceHasFinished = 'experienceHasFinished',
}
interface NotAvailableProps {
  handleChangeAccess: Dispatch<SetStateAction<boolean>>;
  experience: any;
  date: Record<string, any> | null;
}

const buildMessage = (typeOfInvalid: InvalidType, name: string, date) => {
  const messages = {
    experienceHasNotStarted: `Registration for ${name} does not open until
        ${DateTime.fromISO(date.init).toFormat('MM/dd/yyyy')} at ${date.hourFormat}`,
    experienceHasFinished: `The registration window for ${name} has closed.`,
  };
  return messages[typeOfInvalid];
};

const NotAvailable: FunctionComponent<NotAvailableProps> = ({
  handleChangeAccess,
  experience,
  date,
}) => {
  const { openModal } = useModal();
  const {
    id: experienceId,
    name,
    startDate,
    earlyBirdUrlCode,
    earlyBirdRegistrationAvailable,
  } = experience;

  let earlCodeAvailable = false;
  if (earlyBirdRegistrationAvailable && earlyBirdUrlCode !== null && earlyBirdUrlCode !== '') {
    earlCodeAvailable = true;
  }
  const startDateFormatted = useMemo(() => {
    return getDateFromISO(startDate);
  }, [startDate]);

  const handleOpenEarlyBirdModal = () => {
    openModal(MODALS.EARLY_BIRD, {
      handleChangeAccess,
      experienceId,
    });
  };

  const invalidType = useMemo(() => {
    const now = DateTime.now();
    const fullDate = DateTime.fromISO(`${date?.init}T${date?.hour}Z`);
    const startDateIsInvalid = fullDate.diff(now, 'days').days > 0;
    return startDateIsInvalid
      ? InvalidType.experienceHasNotStarted
      : InvalidType.experienceHasFinished;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateFormatted]);

  const enableEarlyBirdOption = invalidType === InvalidType.experienceHasNotStarted;

  return (
    <Container>
      <PersonOffIcon style={{ fontSize: 90 }} />
      <MessageContainer display="flex" variant="h2">
        {buildMessage(invalidType, name || '', date)}
      </MessageContainer>
      {enableEarlyBirdOption && (
        <React.Fragment>
          {earlCodeAvailable && (
            <React.Fragment>
              <MessageContainer display="flex" variant="h5">
                Click below if you have an early bird registration code.
              </MessageContainer>
              <Button onClick={handleOpenEarlyBirdModal}> Early Bird</Button>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <EarlyBird />
    </Container>
  );
};

export { NotAvailable };

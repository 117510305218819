/* eslint-disable prettier/prettier */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Typography,
  Theme,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  buildUrl,
  calculateShoppingCartTotal,
  formatMoney,
  getStateCode,
  getUserFullName,
} from '@vizsla/utils';
import { Modal, ShoppingCart, StripeBillingAddress } from '@vizsla/components';
import {
  BankPaymentMethod,
  CardPaymentMethod,
  UnknownPaymentMethod,
  useExperienceRegistrationByIdQuery,
  useRegisterAttendeesWithOutPaymentMutation,
  useStripePaymentMethodDefaultMutation,
  useStripePaymentMethodsUserQuery,
} from '@vizsla/graphql';
import {
  createCartStore,
  useCurrentUser,
  useModal,
  useNotification,
  useShoppingCartState,
} from '@vizsla/hooks';
import { LANDING_ROUTES, MODALS } from '@vizsla/constants';
import { FontSize, PaletteColor } from '@vizsla/theme';

import { useCheckoutWithPaymentMethod } from 'src/hooks/checkout';
import { SupportPageLayout } from 'src/layouts';
import { useSelectedExperience, useOpenExperiencePages, useSelectedWaiver } from 'src/hooks';
import { PurchaseDescriptorHeader } from 'src/components';

import { ExperienceFee } from './ExperienceFee';
import {
  Button,
  Buttons,
  Container,
  DonationAmount,
  DonationContainer,
  DonationType,
  Header,
  HeaderTitle,
  ProtectedByContainer,
  ProtectedByLabel,
  ProtectedByLogo,
  StripeBillingAddressContainer,
  StripePaymentDetails,
} from './CheckoutPage.styles';
import { AddPaymentMethodStep } from '../AddPaymentMethod';
import { ExternalLink } from '../AddPaymentMethod/PaymentMethodForm.styles';

export const useStyles = makeStyles((theme: Theme) => ({
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  modalButton: {
    margin: theme.spacing(1, 4, 2),
  },
  checkboxLabel: {
    fontSize: FontSize.Default,
    color: PaletteColor.BlackText,
    marginRight: 0,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      '& > span:first-of-type': {
        paddingTop: '2px',
      },
    },
  },
  checkboxStyle: {
    '&.MuiButtonBase': {
      padding: '6px',
    },
  },
}));

const selectAdress = state => ({
  items: state.items,
  addItem: state.add,
  removeItem: state.remove,
});

const selectTotalSectionState = state => {
  return calculateShoppingCartTotal(state.items);
};

interface BillingInfo {
  name?: string;
  address?: {
    city?: string;
    country?: string;
    line1?: string;
    line2?: string | null;
    postal_code: string;
    state?: string;
  };
}

export const useBillingData = createCartStore<any>();

export const useIntegrationData = createCartStore<any>();

export default function CheckoutPageOnePage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [excuetePay, setExecutePay] = useState(false);
  const [validateAddress, setValidateAddress] = useState(false);
  const total = useShoppingCartState(selectTotalSectionState);
  const [checkoutNext, setCheckoutNext] = useState(total > 0);
  const waiverInfo = useSelectedWaiver(state => state.data);
  const { addItem: addBillingInfo, removeItem, items: itemsBilling } = useBillingData(selectAdress);
  const { closeModal } = useModal(MODALS.NO_REQUIRED_FEE);
  const { firstName, data: user } = useCurrentUser();
  const [dataAddress, setDataAddress] = useState<BillingInfo>({});
  const toaster = useNotification();
  const { openRegistrationForm: openOptionsSelector, openPayment } = useOpenExperiencePages();
  const selectItemsState = state => ({ items: state.items });
  const [isCheckedPersonalAddress, setIsCheckedPersonalAddress] = useState(true);
  const [disabledPersonalAddress, setDisabledPersonalAddress] = useState(false);

  const [isAuthorizedCollie, setIsAuthorizedCollie] = useState(false);
  const [keyAddres, setKeyAddress] = useState(0);
  const [termsError, setTermsError] = useState(false);
  const [validatePaymentData, setValidatePaymentData] = useState(false);
  const [completePayment, setCompletePayment] = useState(false);

  const {
    data,
    loading: loadingPaymentMethods,
    refetch: refetchPaymentMethods,
  } = useStripePaymentMethodsUserQuery({
    skip: !user?.id,
    variables: {
      userId: String(user?.id),
    },
  });

  const paymentMethods = useMemo(() => {
    return data?.stripePaymentMethods?.items ?? [];
  }, [data]);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    BankPaymentMethod | CardPaymentMethod | UnknownPaymentMethod | null
  >();

  const noFeeRequired = total <= 0;

  const { items: shoppingItems } = useShoppingCartState(selectItemsState);

  const formattedtotal = React.useMemo(() => formatMoney(total, 2), [total]);

  const formattedItems = shoppingItems?.map((item, index) => {
    if (index === 0) {
      return {
        ...item,
        attendee: {
          ...item.attendee,
          zip:
            item?.attendee?.zip && typeof item?.attendee?.zip !== 'string'
              ? item.attendee.zip.toString()
              : item?.attendee?.zip,
          waiverSignature: waiverInfo?.waiverSignature ? waiverInfo?.waiverSignature : null,
        },
      };
    }
    if (index !== 0 && item.parent) {
      return {
        ...item,
        attendee: {
          ...item.attendee,
          zip:
            item?.attendee?.zip && typeof item?.attendee?.zip !== 'string'
              ? item.attendee.zip.toString()
              : item?.attendee?.zip,
          waiverSignature: waiverInfo?.waiverSignature ? waiverInfo?.waiverSignature : null,
        },
      };
    }
    return item;
  });

  const firstRegistrationOption = useMemo(() => {
    return formattedItems.find(item => item.type === 'registration');
  }, [formattedItems]);

  const [registerAttendeesWithOutPaymentMutation, { loading: loadingRegisterAttendees }] =
    useRegisterAttendeesWithOutPaymentMutation();

  const [paymentMethodDefault] = useStripePaymentMethodDefaultMutation();
  const { experienceId } = useParams();
  const { data: experienceInformation, loading: loadingExperience } =
    useExperienceRegistrationByIdQuery({
      skip: !experienceId,
      variables: { id: experienceId! },
    });

  const validateFields = () => {
    let nextStep = true;
    if (!isAuthorizedCollie && total > 0) {
      setTermsError(true);
      toaster.error('Please, accept the terms and conditions');
      nextStep = false;
    }
    if (isAuthorizedCollie) {
      setTermsError(false);
    }
    if (total > 0 && !complete) {
      toaster.error('Please, complete billing address form');
      setValidateAddress(true);
      nextStep = false;
    }
    if (total > 0 && !completePayment) {
      toaster.error('Please, complete payment method info');
      setValidatePaymentData(true);
      nextStep = false;
    }
    return nextStep;
  };
  const handleNext = async () => {
    try {
      if (!validateFields()) {
        return;
      }
      if (complete && total > 0) {
        // await paymentMethodDefault({
        //   variables: {
        //     userId: user?.id as string,
        //     paymentMethodId: selectedPaymentMethod?.id ?? '',
        //     onlyUser: true,
        //   },
        // });
        setLoading(true);
        setExecutePay(true);
      }
      if (total <= 0) {
        const payment = await registerAttendeesWithOutPaymentMutation({
          variables: {
            data: {
              experience: { id: experienceId },
              shoppingCart: formattedItems,
              billingInfo: dataAddress,
              organizationId: experienceInformation?.experience?.campaign?.vizslaOrganization?.id,
            },
          },
        });
        if (payment.data?.registerAttendeesWithOutPayment?.success) {
          navigate(
            buildUrl(LANDING_ROUTES.EXPERIENCE_THANKS, { experienceId: String(experienceId) }),
          );
        } else {
          toaster.error(
            payment.data?.registerAttendeesWithOutPayment?.message ?? 'Something went wrong',
          );
          navigate(
            buildUrl(LANDING_ROUTES.EXPERIENCE_REGISTRATION, {
              experienceId: String(experienceId),
            }),
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleStartOver = () => {
    openOptionsSelector(experienceId);
  };

  const handleChangePersonalAddress = event => {
    const value = event.target.checked as boolean;

    if (!value) {
      setDataAddress({});
    }
    setIsCheckedPersonalAddress(value);
  };

  const handleChangeAuthorizedCollie = event => {
    const value = event.target.checked as boolean;
    setIsAuthorizedCollie(value);
  };

  const disabled = useMemo(
    () => loadingExperience || !experienceInformation?.experience,
    [loadingExperience, experienceInformation],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (paymentMethods && !selectedPaymentMethod && paymentMethods.length >= 1) {
      setSelectedPaymentMethod(paymentMethods[0]);
      setCompletePayment(true);
    }
  }, [paymentMethods, selectedPaymentMethod]);

  useEffect(() => {
    async function getTaxes() {
      if (!complete && dataAddress.address) {
        try {
          setLoading(true);
          if (!experienceId) {
            toaster.error(`Experience ID must be defined. Got ${typeof experienceId}`);
            return;
          }
          setCheckoutNext(true);
          setLoading(false);
        } catch (error) {
          const errorDetail = { error };
          if (errorDetail.error?.message.includes('Address')) {
            toaster.error('Invalid Address, please verify and try again.');
          } else {
            toaster.error('Something went wrong generating the payment, please try again');
          }
          console.error(error);
          setLoading(false);
        }
      }
      if (complete) {
        setCheckoutNext(false);
        if (itemsBilling.some(item => item.type === 'data')) {
          removeItem(itemsBilling.filter(item => item.type === 'data')[0]);
          addBillingInfo({
            type: 'data',
            address: dataAddress,
          });
        } else {
          addBillingInfo({
            type: 'data',
            address: dataAddress,
          });
        }
      }
    }
    getTaxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complete, dataAddress]);
  useEffect(() => {
    if ((user || firstRegistrationOption) && isCheckedPersonalAddress) {
      const primary = firstRegistrationOption?.attendee;

      if (!user?.address && !primary) {
        setIsCheckedPersonalAddress(false);
        setDisabledPersonalAddress(true);
      } else {
        setDataAddress({
          name: getUserFullName(primary ?? user),
          address: {
            line1: primary?.address ?? user?.address?.street1 ?? '',
            line2: user?.address?.street2 ?? '',
            city: primary?.address?.city ?? user?.address?.city ?? '',
            state: getStateCode(primary?.state ?? user?.address?.state ?? ''),
            postal_code: primary?.zip ?? user?.address?.zip ?? '',
            country: user?.address?.country ?? '',
          },
        });
        setComplete(true);
        setKeyAddress(prev => prev + 1);
      }
    }
  }, [isCheckedPersonalAddress]);

  return (
    <SupportPageLayout>
      <SupportPageLayout.Content>
        <Container>
          <PurchaseDescriptorHeader
            loading={loading}
            subtitle={`You are ${
              experienceInformation?.experience?.experienceType === 'Ticketing'
                ? 'purchasing tickets'
                : 'registering'
            } for`}
            title={experienceInformation?.experience?.name ?? undefined}
          />

          <Typography variant="body1" fontSize="17px" textAlign="center" mt="8px">
            Nice work, {firstName}. You&apos;ll almost there
          </Typography>
          <StripePaymentDetails>
            <Header>
              <HeaderTitle margin="1rem 0">Payment details</HeaderTitle>
              <ProtectedByContainer>
                <ProtectedByLabel>Protected by:</ProtectedByLabel>
                <ProtectedByLogo src="https://cdn.brandfolder.io/KGT2DTA4/at/8vbr8k4mr5xjwk4hxq4t9vs/Stripe_wordmark_-_blurple.svg" />
              </ProtectedByContainer>
            </Header>

            <AddPaymentMethodStep
              paymentMethods={paymentMethods}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              refetch={refetchPaymentMethods}
              loadingPaymentMethods={loadingPaymentMethods}
              excuetePay={excuetePay}
              setExecutePay={setExecutePay}
              setLoading={setLoading}
              validatePaymentData={validatePaymentData}
              setValidatePaymentData={setValidatePaymentData}
              setCompletePayment={setCompletePayment}
            />
          </StripePaymentDetails>

          <StripeBillingAddressContainer>
            <Header>
              <HeaderTitle margin="0">Billing Information</HeaderTitle>
            </Header>

            <Tooltip
              title={
                disabledPersonalAddress ? 'The user does not have a personal address stored.' : ''
              }
              placement="right"
            >
              <FormControlLabel
                classes={{
                  label: classes.checkboxLabel,
                }}
                sx={{ marginBottom: '1rem' }}
                label="Same As Personal Address"
                control={
                  <Checkbox
                    name="sameAsPersonalAddress"
                    checked={isCheckedPersonalAddress}
                    onChange={handleChangePersonalAddress}
                    className={classes.checkboxStyle}
                    disabled={disabledPersonalAddress}
                  />
                }
              />
            </Tooltip>

            {!isCheckedPersonalAddress && (
              <StripeBillingAddress
                key={keyAddres}
                complete={setComplete}
                getData={setDataAddress}
                defaultValues={dataAddress}
                isCheckedPersonalAddress={isCheckedPersonalAddress}
                validateAddress={validateAddress}
                setValidateAddress={setValidateAddress}
              />
            )}
            <ExperienceFee experienceId={experienceId as string} recalculate={checkoutNext} />
            <DonationContainer>
              <DonationType>Total</DonationType>
              <DonationAmount>{formattedtotal}</DonationAmount>
            </DonationContainer>

            <FormControl error={termsError} component="fieldset">
              <FormControlLabel
                className={classes.checkboxLabel}
                control={
                  <Checkbox
                    id="terms"
                    name="terms"
                    color="primary"
                    checked={isAuthorizedCollie}
                    required
                    sx={{
                      color: termsError ? 'error.main' : 'default',
                    }}
                  />
                }
                id="terms"
                name="terms"
                label={
                  <Typography variant="body1">
                    I authorize Collie to charge the credit card indicated in
                    <ExternalLink
                      href="https://www.colliegives.com/terms-of-service"
                      target="_blank"
                    >
                      &nbsp;Collie&apos;s Terms
                    </ExternalLink>
                    &nbsp;and&nbsp;
                    <ExternalLink href="https://www.colliegives.com/privacy-policy" target="_blank">
                      Privacy Policy.
                    </ExternalLink>
                  </Typography>
                }
                onChange={handleChangeAuthorizedCollie}
              />
              {termsError && (
                <FormHelperText>Please accept the terms and conditions.</FormHelperText>
              )}
            </FormControl>
          </StripeBillingAddressContainer>
        </Container>
        <Buttons>
          <Button
            disabled={disabled || loadingRegisterAttendees || loading}
            variant="outlined"
            onClick={handleStartOver}
          >
            Start Over
          </Button>

          <Button loading={loading || loadingRegisterAttendees} onClick={handleNext}>
            Purchase
          </Button>
        </Buttons>
      </SupportPageLayout.Content>

      <SupportPageLayout.Sidebar>
        <ShoppingCart experience={experienceInformation?.experience ?? undefined} showDiscount />
      </SupportPageLayout.Sidebar>
      <Modal isOpen={noFeeRequired} onClose={closeModal}>
        <div className={classes.modalContent}>
          <Typography component="p" className={classes.modalButton}>
            No fee required, Check Out.
          </Typography>
          <Button
            onClick={handleNext}
            disabled={!noFeeRequired}
            loading={loading || loadingRegisterAttendees}
          >
            Check Out
          </Button>
        </div>
      </Modal>
    </SupportPageLayout>
  );
}

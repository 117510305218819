import React, { Fragment } from 'react';
import { Skeleton } from '@mui/material';

import { useExperienceId } from '@vizsla/hooks';

import { ThanksPage } from 'src/components';
import { useExperienceById } from 'src/hooks/experience';

import { useEventStoreContext } from '../../providers/EventStoreProvider';

const CARD_SIZE = 220;

const getSubtitleText = ({ registration, donation }) => {
  if (registration && donation) {
    return ['YOU HAVE BEEN REGISTERED AND', 'DONATED SUCCESSFULLY TO'];
  }
  if (registration) {
    return ['YOU HAVE BEEN REGISTERED TO'];
  }
  if (donation) {
    return ['YOU HAVE SUCCESSFULLY DONATED TO'];
  }
  return [''];
};
export function ExperienceThanks() {
  const experienceId = useExperienceId();
  const { donation, registration } = useEventStoreContext();
  const { experience, loading: loadingExperience } = useExperienceById(experienceId);

  const name = React.useMemo(() => experience?.name ?? 'Experience', [experience]);

  return (
    <Fragment>
      {loadingExperience ? (
        <Skeleton variant="circular" width={CARD_SIZE} height={CARD_SIZE} />
      ) : (
        <ThanksPage title={name} subtitle={getSubtitleText({ registration, donation })} />
      )}
    </Fragment>
  );
}

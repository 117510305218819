import React, { createContext, Dispatch, FC, SetStateAction, useContext, useState } from 'react';

type EventStoreContextType = {
  donation: boolean;
  registration: boolean;
  gift: boolean;
  setDonation: Dispatch<SetStateAction<boolean>>;
  setRegistration: Dispatch<SetStateAction<boolean>>;
  setGift: Dispatch<SetStateAction<boolean>>;
};

const initialEventContext: EventStoreContextType = {
  donation: false,
  registration: false,
  gift: false,
  setDonation: () => {},
  setRegistration: () => {},
  setGift: () => {},
};

const EventStoreContext = createContext<EventStoreContextType>(initialEventContext);

export const useEventStoreContext = () => useContext(EventStoreContext);

export const EventStoreProvider: FC = ({ children }) => {
  const [donation, setDonation] = useState(false);
  const [registration, setRegistration] = useState(false);
  const [gift, setGift] = useState(false);

  return (
    <EventStoreContext.Provider
      value={{ donation, registration, gift, setDonation, setRegistration, setGift }}
    >
      {children}
    </EventStoreContext.Provider>
  );
};

import styled from '@emotion/styled';

export const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

export const FormActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${p => p.theme.spacing(1)};
`;

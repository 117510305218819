import { useSubdomain } from '@vizsla/hooks';
import {
  Experience,
  PublicCampaignInfoBySlugQuery,
  useGetPublicCampaignInfoBySlugQuery,
} from '@vizsla/graphql';

type PublicCampaignInfo = NonNullable<
  NonNullable<PublicCampaignInfoBySlugQuery['dnsRecord']>['campaign']
>;

type CurrentPublicCampaignQueryResult = {
  organization: NonNullable<PublicCampaignInfo['vizslaOrganization']>;
  campaign: Pick<
    PublicCampaignInfo,
    | 'id'
    | 'name'
    | 'logo'
    | 'fundraisingGoal'
    | 'totalRaised'
    | 'contactEmail'
    | 'contactPhone'
    | 'campaignStatus'
    | 'campaignType'
  >;
  experiences: NonNullable<PublicCampaignInfo['experiences']>;
  campaignTemplates: PublicCampaignInfo['campaignTemplates'];
};

export const useCurrentPublicCampaign = () => {
  const subdomain = useSubdomain();

  const { data, loading } = useGetPublicCampaignInfoBySlugQuery({
    variables: {
      slug: subdomain as string,
    },
    skip: !subdomain,
  });

  const publicCampaignInfo = data?.getPublicCampaignInfoBySlug as
    | CurrentPublicCampaignQueryResult
    | undefined;

  const organization = publicCampaignInfo?.organization || null;

  const campaign = publicCampaignInfo?.campaign || null;

  const experiences = (publicCampaignInfo?.experiences.items || []) as Array<Experience>;
  const campaignTemplates = publicCampaignInfo?.campaignTemplates;
  return { organization, campaign, experiences, campaignTemplates, loading };
};

import React from 'react';
import { Skeleton } from '@mui/material';

import { getUserFullName } from '@vizsla/utils';
import { User } from '@vizsla/graphql';

import { AvatarImage, Card, AvatarContainer, Text, Title } from './LeaderBoardCard.style';

export interface Props {
  title: string;
  description: string;

  loading?: boolean;
  name?: string;
  avatar?: string;
}

const CARD_SIZE = 220;

export function LeaderBoardCard(props: Props) {
  const fullname = props.name;
  const imageURI = props.avatar;

  if (props.loading) {
    return <Skeleton variant="circular" width={CARD_SIZE} height={CARD_SIZE} />;
  }

  return (
    <Card size={CARD_SIZE}>
      <AvatarContainer>
        <AvatarImage src={imageURI} />
      </AvatarContainer>

      <Title>{props.title}</Title>

      <Text>
        <b>{fullname}</b>
      </Text>

      <Text>{props.description}</Text>
    </Card>
  );
}

import { gql, useMutation } from '@apollo/client';

import { CheckoutWithPaymentMethodInput } from '@vizsla/graphql';

const MUTATION = gql`
  mutation CheckoutWithPaymentMethod($data: CheckoutWithPaymentMethodInput) {
    checkout: checkoutWithPaymentMethod(data: $data) {
      success
      message
    }
  }
`;

interface MutationVariables {
  data: CheckoutWithPaymentMethodInput;
}

interface Mutation {
  checkout: {
    success: boolean;
    message: string;
  };
}

/** Create a setup payment method intent to attach a payment method from an organization that you administrate. */
export const useCheckoutWithPaymentMethod = () => {
  const [mutate, { loading }] = useMutation<Mutation, MutationVariables>(MUTATION);

  const execute = async (variables: MutationVariables) => {
    const response = await mutate({ variables });
    const checkout = response.data?.checkout;

    if (!checkout) {
      throw new Error(`Response must have "setup". Got ${typeof checkout}`);
    }

    return checkout;
  };

  return {
    execute,
    loading,
  };
};

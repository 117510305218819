import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ShoppingCart } from '@vizsla/components';
import { useExperienceRegistrationByIdQuery } from '@vizsla/graphql';
import { useNotification } from '@vizsla/hooks';

import { SupportPageLayout } from 'src/layouts';
import { PurchaseDescriptorHeader } from 'src/components';
import { useSelectedExperience } from 'src/hooks';

import { Container } from './ExperiencePurchaseLayout.styles';

export const ExperiencePurchaseLayout: React.FC = props => {
  const { experienceId } = useParams();
  const { data: experience, setData, setLoading } = useSelectedExperience();
  const { error: showError } = useNotification();

  const { data, loading } = useExperienceRegistrationByIdQuery({
    skip: !experienceId,
    variables: { id: experienceId! },

    onCompleted(data) {
      if (!data.experience) {
        showError(`No experience found with the given ID`);
        return;
      }

      setData(data.experience);
    },

    onError(err) {
      showError(err.message);
    },
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <SupportPageLayout>
      <SupportPageLayout.Content>
        <Container>
          <PurchaseDescriptorHeader
            loading={loading}
            subtitle={`You are ${
              data?.experience?.experienceType === 'Ticketing'
                ? 'purchasing tickets'
                : 'registering'
            } for`}
            title={experience?.name ?? undefined}
          />
          {props.children}
        </Container>
      </SupportPageLayout.Content>

      <SupportPageLayout.Sidebar>
        <ShoppingCart experience={experience} showDiscount={!!experience} />
      </SupportPageLayout.Sidebar>
    </SupportPageLayout>
  );
};

import React, { useMemo } from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Modal } from '@vizsla/components';
import { useModal, useNotification } from '@vizsla/hooks';
import { MODALS } from '@vizsla/constants';
import { useUserGetDataQuery, User } from '@vizsla/graphql';

const useStyles = makeStyles({
  modalWrapper: {
    '& .MuiDialog-container': {
      justifyContent: 'center',
      backgroundColor: 'rgba(120, 132, 144, 0.2)',
    },
    '& .MuiDialog-paper': {
      width: '25%',
      maxWidth: 'unset',
      maxHeight: 'unset',
      margin: 0,
      padding: '1%',
    },
    '& .MuiDialogContent-root': {
      padding: '2rem 1rem',
    },
  },

  modalButton: {
    display: 'inline-flex',
    width: '100%',
    margin: '8px 0',
  },

  buttonContainer: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0.5rem 1rem',
  },
});

export const SelectMinor = () => {
  const classes = useStyles();
  const { isOpen, closeModal, args } = useModal(MODALS.SELECT_MINOR);
  const notification = useNotification();

  const data = useMemo(() => {
    if (args) {
      return {
        parent: args.parent,
        referenceId: args.referenceId,
        markAsMinor: args.markAsMinor,
        setField: args.setField,
        cleanAsMinor: args.cleanAsMinor,
        setChecked: args.setChecked,
        editCart: args.editCart,
        fields: args.fields,
      };
    }
    return null;
  }, [args]);

  const { data: userData, loading } = useUserGetDataQuery({
    variables: {
      filter: {
        email: { equals: data?.parent.attendee.email },
      },
    },
    skip: !args,
  });

  const conitueNewRegisterMinor = () => {
    data?.markAsMinor();
    notification.warning('Remember to update your payment method in the consumer portal');
    closeModal();
  };
  const onCloseModal = () => {
    data?.cleanAsMinor();
    data?.setChecked(false);
    closeModal();
  };
  const childrens = useMemo(() => {
    if (userData) {
      if (userData.usersList.count > 0) {
        return userData.usersList.items[0].childrens?.items ?? ([] as User[]);
      }
      return [] as User[];
    }
    return [] as User[];
  }, [userData]);

  const handleSelect = (value, newValue) => {
    const children = childrens.filter(item => item.email === value.target.value)[0];
    data?.setField(data.fields.FIELD_FIRST_NAME, children.firstName);
    data?.setField(data.fields.FIELD_LAST_NAME, children.lastName);
    data?.setField(data.fields.FIELD_BIRTHDAY, children.birthDate);
    data?.setField(data.fields.FIELD_GENDER, children.gender);
    data?.setField(data.fields.FIELD_ADDRESS, children.address?.street1);
    data?.setField(data.fields.FIELD_CITY, children.address?.city);
    data?.setField(data.fields.FIELD_STATE, children.address?.state);
    data?.setField(data.fields.FIELD_ZIP, children.address?.zip);
    data?.setField(data.fields.FIELD_EMAIL, children.email);
    data?.setField(data.fields.FIELD_PHONE, children.phone?.number);
    data?.editCart({ id: data?.referenceId, parent: { email: data?.parent.attendee.email } });
    notification.warning('Remember to update your payment method in the consumer portal');
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} customClasses={[classes.modalWrapper]}>
      <Modal.Title>
        <Typography variant="h4">Select the minor you wish to register</Typography>
      </Modal.Title>
      <Modal.Content>
        {loading ? (
          <Grid item sx={{ marginTop: '4px' }}>
            <CircularProgress size={24} color="secondary" />
          </Grid>
        ) : (
          <Grid item xs={6}>
            {childrens?.length > 0 ? (
              <FormControl fullWidth>
                <InputLabel id="select-contact">Select Minor</InputLabel>
                <Select name="minor" labelId="select-minor" onChange={handleSelect}>
                  {childrens.map(item => (
                    <MenuItem key={item?.id} value={item?.email as string}>
                      {item.fullName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Typography variant="body2">There are no minors associated with this user</Typography>
            )}
          </Grid>
        )}
      </Modal.Content>
      <Box className={classes.buttonContainer}>
        <Modal.Action
          type="primary"
          content="None, New Registrant"
          onAction={conitueNewRegisterMinor}
          loading={false}
          className={classes.modalButton}
        />
        <Modal.Action
          type="secondary"
          content="Cancel"
          onAction={onCloseModal}
          className={classes.modalButton}
        />
      </Box>
    </Modal>
  );
};

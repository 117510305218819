import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import PersonOffIcon from '@mui/icons-material/PersonOff';

import { useModal } from '@vizsla/hooks';
import { CampaignStatus } from '@vizsla/types';
import { Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';

export function CampaignStatusPass() {
  const { isOpen, args } = useModal(MODALS.CAMPAIGN_STATUS_MODAL);

  const status = useMemo(() => {
    return args ? args.campaignStatus : undefined;
  }, [args]);

  return (
    <Modal isOpen={isOpen} onClose={() => {}} fullWidth>
      <Modal.Content>
        {status && (
          <Grid container>
            <Grid item xs={12} textAlign="center">
              <PersonOffIcon style={{ fontSize: 90 }} />
            </Grid>
            <Grid item>
              {status === CampaignStatus.paused && (
                <Typography variant="h2" textAlign="center">
                  {' '}
                  The event is on pause; please come back later.{' '}
                </Typography>
              )}
              {status === CampaignStatus.draft && (
                <Typography variant="h2" textAlign="center">
                  {' '}
                  The event is not available; please come back later.{' '}
                </Typography>
              )}
              {status === CampaignStatus.completed && (
                <Typography variant="h2" textAlign="center">
                  {' '}
                  The event is closed, thanks for participating.{' '}
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </Modal.Content>
    </Modal>
  );
}

import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { DonationFormValues } from '@vizsla/components';
import { useIndividualFundraisingLazyQuery } from '@vizsla/graphql';
import { useCurrentUser, useNotification, useShoppingCartState } from '@vizsla/hooks';
import { validateWithSchema, formatDate } from '@vizsla/utils';
import { DateFormatPatterns } from '@vizsla/constants';
import { IntegrationType, DefaultIntegrationCredentialsType } from '@vizsla/types';

import { DONATION_FORM_SCHEMA } from 'src/constants/validationSchemas';
import { useSelectedFundraser, useOpenExperiencePages } from 'src/hooks';
import { useEventStoreContext } from 'src/providers/EventStoreProvider';

import { selectDonationState } from '../ExperienceDonationStep/ExperienceDonationStep';

export function useTeamFundraisingDonate() {
  const { setDonation } = useEventStoreContext();
  const {
    data: fundraiser,
    loading: fetching,
    setData: setFundraiser,
    setLoading: setFundraiserFetching,
  } = useSelectedFundraser();

  const { donation, addCart: addToCart, removeCart } = useShoppingCartState(selectDonationState);
  const { user } = useCurrentUser();
  const { fundraiserId } = useParams();

  const toaster = useNotification();
  const [executeFundraiserQuery] = useIndividualFundraisingLazyQuery();
  const { openBillingAddress } = useOpenExperiencePages();

  const refetch = useCallback(async () => {
    if (!fundraiserId) return;

    setFundraiserFetching(true);

    const response = await executeFundraiserQuery({
      variables: {
        filter: {
          OR: [
            { id: { equals: fundraiserId } },
            { webLink: { equals: fundraiserId } },
            { attendee: { id: { equals: fundraiserId } } },
          ],
        },
      },
    });
    const team = response.data?.fundraisers?.items?.[0];
    if (team) {
      setFundraiser(team);
      setFundraiserFetching(false);
    }
  }, [executeFundraiserQuery, fundraiserId, setFundraiser, setFundraiserFetching]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleSubmit = async (values: DonationFormValues) => {
    setDonation(true);
    const price = values.amount ?? 0;
    const makeAnonymous = values.makeAnonymous ?? false;

    if (!fundraiser?.id) {
      toaster.error('Fundraiser must be defined');
      return;
    }

    if (!fundraiser?.attendee?.experience?.id) {
      toaster.error('Experience must be defined.');
      return;
    }

    if (!user?.id) {
      toaster.error('User must be defined.');
      return;
    }

    if (donation) removeCart(donation);

    addToCart({
      type: 'donation',
      donor: { id: user.id },
      allocation: { type: 'fundraiser', id: fundraiser.id },
      experience: { id: fundraiser.attendee?.experience?.id },
      message: values.message,
      makeAnonymous,
      price,
      frecuency: values.frecuency,
      questions: values.donorsGroupquestions,
      startDate: formatDate(values.startDate, DateFormatPatterns.shortDateWithDash) ?? undefined,
      endDate: formatDate(values.endDate, DateFormatPatterns.shortDateWithDash) ?? undefined,
      organizationId: fundraiser?.attendee?.experience?.campaign?.vizslaOrganization?.id ?? '',
      integrationType: values.integrationType as IntegrationType,
      integrationCredentials: values.integrationCredentials as DefaultIntegrationCredentialsType,
      integrationCompany: values.integrationCompany as string,
    });

    openBillingAddress(fundraiser.attendee.experience.id);
  };

  const validateForm = (values: DonationFormValues) => {
    return validateWithSchema(DONATION_FORM_SCHEMA, values);
  };

  return {
    fundraiser,
    fetching,

    validateForm,
    handleSubmit,
  };
}

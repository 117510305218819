import React from 'react';
import { Grid, Theme, Typography, typographyClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { PaletteColor } from '@vizsla/theme';
import { Attendee } from '@vizsla/graphql';
import { formatDate } from '@vizsla/utils';
import { TableColumn, TableData, UserAvatarFullNameInline } from '@vizsla/components';
import { Nullable } from '@vizsla/types';
import { DateFormatPatterns } from '@vizsla/constants';

const useStyles = makeStyles((theme: Theme) => ({
  subTitle: {
    [`&.${typographyClasses.root}`]: {
      color: PaletteColor.GrayText,
    },
  },
  tableRowAction: {
    [`&.${typographyClasses.root}`]: {
      color: PaletteColor.DefaultText,
    },
  },
}));

interface AttendeesTableProps {
  attendees: Nullable<Attendee[]>;
  styles: any;
  loading?: boolean;
}

export const AttendeesTable: React.FC<AttendeesTableProps> = ({
  styles,
  attendees,
  loading = false,
}) => {
  const classes = useStyles();
  const { pageBackgroundSecondColor, accentColor } = styles;

  const columns: Array<TableColumn<Attendee>> = [
    {
      key: 'user.fistName',
      sortable: true,
      title: 'user',
      render: (attendee: Attendee) => {
        return (
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundColor: pageBackgroundSecondColor || PaletteColor.White,
              borderColor: accentColor || PaletteColor.Black,
            }}
          >
            <Grid item>
              <UserAvatarFullNameInline
                user={attendee?.user}
                additionalCaption={
                  <Typography variant="caption">
                    {formatDate(attendee.createdAt, DateFormatPatterns.shortMonthDayYear)}
                  </Typography>
                }
              />
            </Grid>
            <Grid item>
              <Typography variant="caption" className={classes.tableRowAction}>
                {attendee.registrationOption?.name}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Grid>
      <Grid>
        <Typography variant="caption" className={classes.subTitle}>
          Registered Attendees
        </Typography>
      </Grid>
      <TableData hideTitle dataSource={attendees ?? []} columns={columns} loading={loading} />
    </Grid>
  );
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styled from '@emotion/styled';

import { Maybe } from '@vizsla/graphql';
import { buildUrl, calcPercentage, formatMoney } from '@vizsla/utils';
import { LANDING_ROUTES } from '@vizsla/constants';

import { GradientDivider, GradientLinearProgress, LandingButton } from 'src/components/shared';
import { ParticipatingType } from 'src/constants/participating';

const MAIN_COLOR = '#EC308C';

const useStyles = makeStyles({
  boldText: {
    fontWeight: 'bold',
  },
});

const StyledAvatar = styled(Avatar)`
  height: 100%;
`;

type ParticipatingCardProps = {
  type: ParticipatingType;
  name: Maybe<string | undefined>;
  fundraisingGoal: Maybe<number | undefined>;
  totalRaised: Maybe<number | undefined>;
  img?: string | undefined;
  team?: any | undefined;
  experienceId?: string | undefined;
};

const CONSUMER_URL = `${process.env.REACT_APP_CONSUMER_APP_URL}`;

export const ParticipatingCard: React.FC<ParticipatingCardProps> = ({
  type,
  name,
  fundraisingGoal,
  totalRaised,
  img,
  team,
  experienceId,
}) => {
  const classes = useStyles();

  const calculatePercentage = calcPercentage(totalRaised || 0, fundraisingGoal || 0);
  const percentage = calculatePercentage >= 100 ? 100 : calculatePercentage;
  const navigate = useNavigate();

  const isTeamCaptain = type === ParticipatingType.teamCamptain;
  const isTeam = type === ParticipatingType.myTeam;

  const avatarVariant = isTeam || isTeamCaptain ? 'circular' : 'square';
  const avatarWidth = isTeam || isTeamCaptain ? 56 : 150;
  const avatarHeight = isTeam || isTeamCaptain ? 56 : 80;
  const goToTeam = () => {
    const id = team?.webLink ?? team?.id ?? '';
    const path = buildUrl(LANDING_ROUTES.TEAM, { teamId: id });
    navigate(path);
  };

  const raised = formatMoney(totalRaised);
  const goal = formatMoney(fundraisingGoal);

  const redirectToExperience = (expId: string) => {
    const INITIAL_ROUTE = `/experience/${expId}`;

    navigate(INITIAL_ROUTE);
  };

  return (
    <React.Fragment>
      <Grid width="100%" item marginTop="25px">
        <GradientDivider color="#e9e9e9" />
      </Grid>
      <Grid item marginTop="25px">
        <Grid flexDirection="column" alignItems="center" container>
          <Grid item>
            <Typography variant="tabs">{type}</Typography>
          </Grid>
          <Grid item marginTop="20px">
            <StyledAvatar
              src={img}
              sx={{
                width: avatarWidth,
                height: avatarHeight,
                cursor: type === ParticipatingType.benefitingCharity ? 'pointer' : 'default',
              }}
              variant={avatarVariant}
              onClick={() => {
                if (type === ParticipatingType.benefitingCharity) {
                  redirectToExperience(experienceId ?? '');
                }
              }}
            />
          </Grid>

          <Grid item marginTop="10px">
            <Typography variant="h5">{name}</Typography>
          </Grid>

          <Grid marginTop="8px" maxWidth="220px" width="100%" item>
            <GradientLinearProgress height="8px" variant="determinate" value={percentage} />
          </Grid>

          <Grid marginTop="6px" item>
            <Typography variant="body1">
              <span className={classes.boldText}>{raised}</span>
              &nbsp;of a goal of&nbsp;
              <span className={classes.boldText}>{goal}</span>
            </Typography>
          </Grid>

          {isTeam && (
            <Grid item marginTop="21px">
              <LandingButton themeColor={MAIN_COLOR} onClick={goToTeam}>
                GO TO TEAM
              </LandingButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

/* eslint-disable import/order */
// eslint-disable-next-line import/no-unresolved
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { ALL_ROUTES } from 'src/routes';
import { MainLayout, ProtectedRouteWrapper } from 'src/layouts';
import { PublicPageProtectedRoute } from 'src/components/shared';

import type { PublicRouteProps } from '@vizsla/types';

const renderChildRoutes = (routes: PublicRouteProps[] = []) => {
  const renderRouteContent = (routeProps: PublicRouteProps) => {
    const { element, childRoutes, isPrivate, ...restProps } = routeProps;
    return (
      <Route
        key={routeProps.path || 'index'}
        path={routeProps.path}
        element={
          <PublicPageProtectedRoute
            key={routeProps.path || 'index'}
            component={element as React.ComponentType}
            layout={ProtectedRouteWrapper}
            isPrivate={isPrivate}
            componentProps={<React.Fragment />}
            {...restProps}
          />
        }
      >
        {renderChildRoutes(childRoutes)}
      </Route>
    );
  };

  return routes.map(routeProps => renderRouteContent(routeProps));
};

export const AppRouter: React.FC = () => {
  return (
    <Routes>
      {ALL_ROUTES.map(({ element, childRoutes, path, isPrivate }) => {
        return (
          <Route
            key={path}
            path={path}
            element={
              <PublicPageProtectedRoute
                key={path}
                component={element as React.ComponentType}
                layout={MainLayout}
                isPrivate={isPrivate}
                componentProps={<React.Fragment />}
              />
            }
          >
            {renderChildRoutes(childRoutes)}
          </Route>
        );
      })}
    </Routes>
  );
};

import React, { useMemo } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { CardPaymentMethodBrand } from '@vizsla/graphql';

import {
  HeaderCard,
  Reference,
  FooterCardLogo,
  IconContainer,
  CardBrandImage,
  Exp,
} from './paymentMethods.styles';
import {
  LOGO_AMEX,
  LOGO_DISCOVER,
  LOGO_MASTERCARD,
  LOGO_VISA,
  LOGO_VISA_TRANSPARENT,
  LOGO_DISCOVER_TRANSPARENT,
} from '../../assets/paymentMethodsLogos';
import {
  PaymentCard,
  PaymentCardChip,
  PaymentCardChipLine,
  PaymentCardChipMain,
  PaymentCardExpDate,
  PaymentCardFront,
  PaymentCardHolder,
  PaymentCardHolderInfo,
  PaymentCardInfoBottom,
  PaymentCardInner,
  PaymentCardNumber,
  PaymentCardNumberSection,
  PaymentLogo,
} from './PaymentMethodForm.styles';

const CARD_IMAGES: Partial<Record<CardPaymentMethodBrand, string>> = {
  AMEX: LOGO_AMEX,
  MASTERDCARD: LOGO_MASTERCARD,
  VISA: LOGO_VISA_TRANSPARENT,
  DISCOVER: LOGO_DISCOVER_TRANSPARENT,
};

export function PaymentMethodCard(props) {
  const method = useMemo(() => props.data, [props.data]);

  if (method?.primary && !props.selectedPaymentMethod) {
    props.setSelectedPaymentMethod(method);
    props.setCompletePayment(true);
  }
  const onClick = () => {
    props.setSelectedPaymentMethod(method);
    props.setCompletePayment(true);
  };
  // eslint-disable-next-line no-underscore-dangle
  if (method.__typename === 'CardPaymentMethod') {
    // const brand = formantCardPaymentMethodBrand(method.brand);

    return (
      <React.Fragment>
        <PaymentCardFront
          onClick={onClick}
          style={{
            boxShadow:
              props.selectedPaymentMethod?.id === method?.id
                ? 'rgb(40 209 46 / 16%) 0px 1px 4px, #00BFD4 0px 0px 0px 7px'
                : 'none',
          }}
        >
          <PaymentCardChip>
            <PaymentCardChipLine />
            <PaymentCardChipLine />
            <PaymentCardChipLine />
            <PaymentCardChipLine />
            <PaymentCardChipMain />
          </PaymentCardChip>

          <PaymentCardNumber>
            <PaymentCardNumberSection>xxxx</PaymentCardNumberSection>
            <PaymentCardNumberSection>xxxx</PaymentCardNumberSection>
            <PaymentCardNumberSection>xxxx</PaymentCardNumberSection>
            <PaymentCardNumberSection>{method.reference}</PaymentCardNumberSection>
          </PaymentCardNumber>

          <PaymentCardInfoBottom>
            <PaymentCardHolderInfo>
              <PaymentCardExpDate>
                {method.exp !== null ? <Exp>Exp {method.exp}</Exp> : ''}
              </PaymentCardExpDate>
              <PaymentLogo>
                <CardBrandImage src={CARD_IMAGES[method.brand]} />
              </PaymentLogo>
            </PaymentCardHolderInfo>
          </PaymentCardInfoBottom>
        </PaymentCardFront>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={11}>
          <Box display="flex" alignItems="center">
            <Typography
              style={{
                marginRight: '2px',
              }}
              variant="h4"
              align="inherit"
              paragraph={false}
            >
              Payment Method
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          No Data
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

import { calcPercentage } from '@vizsla/utils';
import {
  CampaignTeamMember,
  Team_FundraiserLandingItem,
  TeamCaptainTeamFundraiserLanding,
  useTeamFundraisingLandingQuery,
} from '@vizsla/graphql';

export const useTeamLandingData = (id?: string) => {
  const { data: teamLandingData, loading } = useTeamFundraisingLandingQuery({
    skip: !id,
    variables: { idOrWebLink: id as string },
  });
  const [team] = (teamLandingData?.teamFundraiserLandingData?.items ??
    []) as Team_FundraiserLandingItem[];
  const [teamCaptain] = (team?.teamCaptain?.items ?? []) as TeamCaptainTeamFundraiserLanding[];
  const members = (team?.members?.items ?? []) as CampaignTeamMember[];

  const teamPercentage = calcPercentage(team?.totalRaised || 0, team?.fundraiserGoal || 0);

  const experience = team?.experience;

  return {
    team,
    teamPercentage: teamPercentage >= 100 ? 100 : teamPercentage,
    loading,
    experience,
    teamCaptain: teamCaptain?.attendee,
    campaign: team?.campaign,
    members,
  };
};

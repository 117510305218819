import styled from '@emotion/styled';

import { Button as ButtonBase } from '@vizsla/components';

/// Content

export const Content = styled.div`
  margin: 2rem 0rem;
`;

/// Buttons

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

/// Button

export const Button = styled(ButtonBase)`
  margin: 0;
`;

import styled from '@emotion/styled';
import { Skeleton, Typography, Grid, Box } from '@mui/material';

const ICON_SIZE = '3rem';

export const Container = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.2);
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  width: 100%;
`;

// TODO: check unused
export const TinyTitle = styled(Typography)`
  margin-top: 4px;
`;

export const FormLabel = styled(Typography)`
  margin-bottom: 10px;
  font-weight: bold;
`;

export const Actions = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 0.5rem;
`;

// TODO: check unused
export const CardSkeleton = styled(Skeleton)`
  min-height: 8rem;
  border-radius: 0.8rem;
`;

export const HeaderCard = styled.div`
  display: grid;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
`;

export const FooterCardLogo = styled.div`
  display: grid;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 5;
  grid-row-end: 5;
  justify-items: bottom;
  margin: auto;
`;

// TODO: check unused
export const FooterCardActions = styled.div`
  display: grid;
  grid-column-start: 2;
  grid-column-end: 9;
  grid-row-start: 5;
  grid-row-end: 5;
  justify-items: bottom;
`;

export const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 250px;
  height: 150px;
  padding: 1.5rem;
  background-color: white;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconContainer = styled.div`
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const CardBrandImage = styled.img`
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  object-fit: contain;
`;

const ALLOWED_METHOD_IMAGE_WIDTH = '5rem';
const ALLOWED_METHOD_IMAGE_HEIGHT = '3.5rem';

// TODO: check unused
export const AllowedMethodImage = styled.img`
  width: ${ALLOWED_METHOD_IMAGE_WIDTH};
  height: ${ALLOWED_METHOD_IMAGE_HEIGHT};
  object-fit: contain;
  border: solid 0.1rem #eee;
  border-radius: 0.5rem;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
`;

export const Reference = styled(Typography)`
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.1rem;
`;

export const Name = styled(Typography)`
  font-weight: bold;
`;

export const ContainerCards = styled(Grid)(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
  gap: '24px',
  height: '100%',
  padding: '1rem 0',
}));

export const Exp = styled(Typography)`
  margin-left: auto;
  color: white;
  letter-spacing: 0.05rem;
  font-size: 14px;
`;

import React from 'react';

import { CartItem, ShoppingCartRegistrationData } from '@vizsla/types';

import { ExperienceAttendeeTab } from '../ExperienceAttendeeTab';
import { AttendeesTabContainer } from './ExperienceAttendeeTabs.styles';

interface ExperienceAttendeeTabsProps {
  registrations: CartItem<ShoppingCartRegistrationData>[];
  active: number;
  onActivate: (index: number) => void;
}

export function ExperienceAttendeeTabs(props: ExperienceAttendeeTabsProps) {
  const { registrations, active, onActivate } = props;
  if (registrations.length <= 1) return null;

  return (
    <AttendeesTabContainer>
      {registrations.map((registration, index) => (
        <ExperienceAttendeeTab
          key={registration.id}
          index={index}
          active={active === index}
          onClick={() => onActivate(index)}
        />
      ))}
    </AttendeesTabContainer>
  );
}

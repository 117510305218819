import React from 'react';
import { makeStyles } from '@mui/styles';

import { Modal } from '@vizsla/components';
import { useAppAuth, useModal } from '@vizsla/hooks';

import { MODALS } from 'src/constants/modals';

import { SendCodeByEmailForm } from './SendCodeByEmailForm';
import { ResetPasswordAndVerifyCodeForm } from './ResetPasswordAndVerifyCodeForm';

const useStyles = makeStyles({
  modalWrapper: {
    '& .MuiDialog-container': {
      justifyContent: 'center',
    },
    '& .MuiDialog-paper': {
      width: '400px',
      maxWidth: '600px',
      maxHeight: 'unset',
      margin: 0,
      padding: '1%',
    },
    '& .MuiPaper-root': {
      display: 'block',
    },
    '& .MuiDialogContent-root': {
      overflowY: 'auto',
      scrollbarWidth: 'auto',
      maxHeight: '50vh',
    },
  },
  signupText: {
    color: 'white',
  },
  textContainer: {
    margin: 'auto !important',
  },
  text2ndContainer: {
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    marginTop: '-10px',
  },
  resendButton: {
    marginTop: '-10px',
  },
  inputFont: {
    '& input': {
      fontSize: 'clamp(12px, 2vw, 16px)',
    },
  },
});

export type ForgotPasswordState = {
  email: string;
  isEmailSent: boolean;
};

export const ForgotPasswordModal: React.FC = () => {
  const classes = useStyles();
  const { forgotPasswordCodeVerification, forgotPassword } = useAppAuth();
  const { isOpen, closeModal, openModal, args } = useModal(MODALS.FORGOT_PASSWORD_MODAL);
  const [{ isEmailSent, email }, setForgotPasswordState] = React.useState<ForgotPasswordState>({
    email: '',
    isEmailSent: false,
  });

  const onSendCode = React.useCallback(
    async ({ email }) => {
      await forgotPassword(email);
      setForgotPasswordState({ email, isEmailSent: true });
    },
    [forgotPassword],
  );

  const resendCode = React.useCallback(() => forgotPassword(email), [email, forgotPassword]);

  const closePasswordModal = React.useCallback(() => {
    closeModal();
    args?.onClose?.();
  }, [args, closeModal]);

  const onResetPassword = React.useCallback(
    async ({ email, code, password }) => {
      await forgotPasswordCodeVerification({ email, code, password });
      closePasswordModal();
    },
    [forgotPasswordCodeVerification, closePasswordModal],
  );

  const backToLogin = (reset: (initialValues?: Partial<any> | undefined) => void) => {
    reset();
    closeModal();
    setForgotPasswordState({
      email: '',
      isEmailSent: false,
    });
    openModal(MODALS.LOGIN_MODAL, args);
  };

  const backToChangeEmail = React.useCallback(() => {
    setForgotPasswordState({
      email: '',
      isEmailSent: false,
    });
  }, []);

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} onClose={closePasswordModal} customClasses={[classes.modalWrapper]}>
        <Modal.Title>Forgot Password</Modal.Title>
        <Modal.Content>
          {!isEmailSent && (
            <SendCodeByEmailForm
              classes={classes}
              onSubmit={onSendCode}
              onBackToLogin={backToLogin}
            />
          )}
          {isEmailSent && (
            <ResetPasswordAndVerifyCodeForm
              classes={classes}
              email={email}
              onSubmit={onResetPassword}
              onResendCode={resendCode}
              onBackChangeEmail={backToChangeEmail}
              onBackToLogin={backToLogin}
            />
          )}
        </Modal.Content>
      </Modal>
    </React.Fragment>
  );
};

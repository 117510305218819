import { Alert, Button, Typography } from '@mui/material';
import { Form } from 'react-final-form';
import { LoadingButton } from '@mui/lab';

import {
  TeamFundraisingCreateInput,
  TeamFundraisingKeyFilter,
  useTeamFundraisingCreateMutation,
} from '@vizsla/graphql';
import { Field, NumberField, TextField } from '@vizsla/components';
import { isShoppingCartRegistrationItem, validateWithSchema } from '@vizsla/utils';
import { DECIMAL_MONEY_FORMAT } from '@vizsla/constants';
import { useNotification, useShoppingCartState } from '@vizsla/hooks';

import { useSelectedExperience } from 'src/hooks';

import { Buttons, Container, Content } from './ExperienceTeamCreate.styles';
import { ExperienceTeamCreateFormSchema } from './ExperienceTeamCreate.validations';

interface Values {
  name: string;
  fundraisingGoal?: number;
}

interface Props {
  onCreated(): void;
  onDismiss(): void;
}

export function ExperienceTeamCreate(props: Props) {
  const { data: experience } = useSelectedExperience();
  const { error: showError } = useNotification();
  const { items: cart, setMany: setManyCart } = useShoppingCartState();

  const validateForm = (values: Values) => {
    return validateWithSchema(ExperienceTeamCreateFormSchema, values);
  };

  const onSubmit = async (values: Values) => {
    try {
      const registrations = cart
        // Filter by registration options only.
        .filter(isShoppingCartRegistrationItem)
        // Set to all the registration options the given team.
        .map(registration => {
          const payload: typeof registration = {
            ...registration,

            team: {
              /* This is a placeholder ID used to inform the server that a new team is being created */
              id: 'cltti7fxr007q09jv1aeq6of4',
              name: values.name,
              fundraiserGoal: values.fundraisingGoal,
            },
          };

          return payload;
        });

      setManyCart(registrations);
      props.onCreated();
    } catch (err) {
      console.error(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  const onDismiss = () => {
    props.onDismiss();
  };

  return (
    <Container>
      <Typography>
        Teams connect you with others and help amplify any fundraising efforts. Choose a name for
        your team and incorporate all attendees as team members, or proceed without a team.
      </Typography>

      <Alert color="info">
        You will be able to edit additional team details once the registration process is complete.
      </Alert>

      <Form validate={validateForm} onSubmit={onSubmit}>
        {form => (
          <Content>
            <Field name="name" label="Team Name" placeholder="My Team Name" component={TextField} />

            <Field
              name="fundraisingGoal"
              label="Team Fundraising Goal"
              component={NumberField}
              numberFormat={DECIMAL_MONEY_FORMAT}
            />

            <Buttons>
              <Button variant="outlined" onClick={onDismiss}>
                Continue without a team
              </Button>

              <LoadingButton loading={form.submitting} onClick={form.handleSubmit}>
                Continue
              </LoadingButton>
            </Buttons>
          </Content>
        )}
      </Form>
    </Container>
  );
}

import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
`;

export const TeamContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.8rem;
  justify-content: space-evenly;
  margin-top: 1rem;

  > * {
    flex: 0 1 auto;
  }
`;

import React, { FC, useContext } from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useExperienceId, useShoppingCartState, selectAllDonations } from '@vizsla/hooks';
import { useExperienceLandingQuery } from '@vizsla/graphql';
import { formatMoney } from '@vizsla/utils';

import { LandingStateContext } from '../providers/campaign/ThankYouLandingContext';

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    fontSize: '18px',
  },
  title: {
    fontSize: '26px',
  },
}));

const selectDonationsState = state => {
  const items = selectAllDonations(state);
  const total = items.reduce((acc, item) => acc + item.price, 0);
  return total;
};

type DonationInformationProps = {
  integration: string;
  company: string;
  amount: string;
};

export const DonationInformation: FC<DonationInformationProps> = ({
  integration,
  company,
  amount,
}) => {
  const {
    state: { theme },
  } = useContext(LandingStateContext);
  const id = useExperienceId();
  const { data } = useExperienceLandingQuery({
    variables: {
      id,
    },
  });

  const hasMatch = integration && company && amount;

  const totalDonationsByAttendees = useShoppingCartState(selectDonationsState);

  const { accentColor } = theme;
  const isDonationInformationTitleVisible = theme?.isDonationInformationTitleVisible ?? true;
  const isDonationInformationDescriptionVisible =
    theme?.isDonationInformationDescriptionVisible ?? true;

  const classes = useStyles({ accentColor });

  const lastDonation = data?.experience?.donations?.items.slice(-1)[0];
  const firstOrderNumber = lastDonation?.order?.number;

  return (
    <Grid>
      {isDonationInformationTitleVisible && (
        <Typography variant="h4" textAlign="center" marginBottom={1} className={classes.title}>
          Donation Information
        </Typography>
      )}
      {isDonationInformationDescriptionVisible && (
        <Grid container>
          <Grid item xs={4}>
            <Typography>Amount</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              {totalDonationsByAttendees
                ? formatMoney(totalDonationsByAttendees, 2)
                : 'Amount Donation'}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography>Confirmation Number</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{firstOrderNumber ?? 'Number Donation'}</Typography>
          </Grid>

          {hasMatch && (
            <React.Fragment>
              <Grid item xs={4}>
                <Typography>Donation Match</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{integration}</Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography>Company</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{company}</Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography>Matched Amount</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{formatMoney(Number(amount), 2)}</Typography>
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={12} sx={{ marginTop: 3 }}>
            <Typography fontWeight="Bold">
              {hasMatch
                ? "Thanks! You'll get two emails: one with instructions and another with your receipt."
                : 'A copy of your receipt will be sent to your email shortly'}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

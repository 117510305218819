import { useTeamFundraisingByExperienceIdQuery } from '@vizsla/graphql';

import { useSelectedExperience } from 'src/hooks';

/**
 * Gets the teams available to join of the `selectedExperience` from `useSelectedExperience`.
 */
export function useExperienceTeams() {
  const { data: selectedExperience, loading: selectedExperienceLoading } = useSelectedExperience();

  const { data: response, loading } = useTeamFundraisingByExperienceIdQuery({
    skip: !selectedExperience,
    fetchPolicy: 'cache-and-network',
    variables: { experienceID: selectedExperience?.id as string },
  });

  const data = response?.teams.items ?? [];

  return {
    data,
    loading: loading || selectedExperienceLoading,
  };
}

import React, { useMemo } from 'react';
import { Skeleton, Typography } from '@mui/material';
import { useForm } from 'react-final-form';

import { CheckboxField, Field, FormSpy, TextField } from '@vizsla/components';
import { selectAllRegistrations, useShoppingCartState } from '@vizsla/hooks';

import { useSelectedExperience, useSelectedWaiver } from 'src/hooks';

import {
  FormContainer,
  FormControl,
  FormSection,
  FormTitle,
  HTMLRender,
} from './ExperienceWaiverForm.style';

export interface FormValues {
  waiverAcceptance: boolean;
  waiverSignature: string;
}

const SCROLLABLE_THRESHOLD = 50;
const validateCanSign = (target: HTMLDivElement) => {
  const scrollable = target.scrollHeight - target.clientHeight;

  const hasEnoughSpace = scrollable > SCROLLABLE_THRESHOLD;
  const hasEnded = target.scrollTop > SCROLLABLE_THRESHOLD;

  return !hasEnoughSpace || hasEnded;
};

export function ExperienceWaiverForm() {
  const WAIVER_MINIMUM_LENGTH = 30;
  const { data: waiverInfo, setData } = useSelectedWaiver();
  const FormApi = useForm<FormValues>();
  const { items, editMany } = useShoppingCartState(state => ({
    items: selectAllRegistrations(state),
    editMany: state.editMany,
  }));

  const { data: experience, loading: fetching } = useSelectedExperience();

  const waiverRef = React.useRef<HTMLDivElement>(null);
  const [canSign, setCanSign] = React.useState(false);

  const handleScroll = React.useCallback(() => {
    if (!waiverRef.current) return;
    setCanSign(validateCanSign(waiverRef.current));
  }, []);

  const handleWaiverAcceptanceChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      FormApi.change('waiverAcceptance', e.target.checked);
      if (!e.target.checked) {
        FormApi.change('waiverSignature', '');
        setData({
          waiverAcceptance: false,
          waiverSignature: '',
        });
      }
    },
    [FormApi, setData],
  );

  const handleWaiverSignatureChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const signature = e.target.value;
      FormApi.change('waiverSignature', signature);

      setData({
        waiverAcceptance: true,
        waiverSignature: signature,
      });
    },
    [FormApi, setData],
  );

  React.useEffect(() => {
    if (!waiverRef.current) return;
    setCanSign(validateCanSign(waiverRef.current));
  }, []);

  const hasMinor = useMemo(() => {
    return items.some(item => Boolean(item.parent?.email));
  }, [items]);

  const label = hasMinor
    ? 'By checking this box, I agree to the waiver and Privacy Policy and that I am 18 or older or that I have the authority to register these participants and agree to the waiver and Privacy Policy for them. *'
    : 'I accept the waiver agreement *';

  const experienceWaiverTextLength = experience?.waiverTextBody?.length ?? 0;

  return (
    <FormContainer>
      <FormTitle>Waiver</FormTitle>

      {fetching && <Skeleton height={220} variant="rectangular" />}

      {experienceWaiverTextLength > WAIVER_MINIMUM_LENGTH && (
        <Typography id="waiverLabelDescription" color="gray" fontSize="14px">
          Scroll through entire waiver before continuing.
        </Typography>
      )}

      {experience?.waiverTextBody && !fetching && (
        <HTMLRender
          id="waiverTextDescription"
          ref={waiverRef}
          onScroll={handleScroll}
          html={experience.waiverTextBody}
        />
      )}

      <FormSection>
        <FormControl>
          <Field
            id="waiverAcceptanceControl"
            name="waiverAcceptance"
            label={label}
            component={CheckboxField}
            disabled={!canSign}
            required
            onChange={handleWaiverAcceptanceChange}
          />
        </FormControl>

        <FormSpy subscription={{ values: true }}>
          {({ values }) =>
            values.waiverAcceptance && (
              <FormControl>
                <Field
                  id="waiverSignatureControl"
                  label="Signature"
                  name="waiverSignature"
                  component={TextField}
                  required
                  fullWidth
                  onChange={handleWaiverSignatureChange}
                />
              </FormControl>
            )
          }
        </FormSpy>
      </FormSection>
    </FormContainer>
  );
}

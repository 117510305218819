import { useMemo } from 'react';
import { DateTime } from 'luxon';

import {
  TicketingOption,
  TicketingOptionFilter,
  useTicketingOptionListQuery,
} from '@vizsla/graphql';
import { convertToUTCGeo } from '@vizsla/utils';

const buildDateFilter = (currentISODate: string): TicketingOptionFilter => ({
  AND: [
    {
      OR: [
        { ticketingAvailabilityStartDateTime: { is_empty: true } },
        { ticketingAvailabilityStartDateTime: { lte: currentISODate } },
      ],
    },
    {
      OR: [
        { ticketingAvailabilityEndDateTime: { is_empty: true } },
        { ticketingAvailabilityEndDateTime: { gte: currentISODate } },
      ],
    },
    {
      OR: [
        {
          pricingSettings: {
            pricingType: { equals: 'Scaled' },
            pricingTiers: {
              some: {
                AND: [
                  {
                    OR: [{ startDate: { is_empty: true } }, { startDate: { lte: currentISODate } }],
                  },
                  {
                    OR: [{ endDate: { is_empty: true } }, { endDate: { gte: currentISODate } }],
                  },
                ],
              },
            },
          },
        },
        {
          pricingSettings: {
            pricingType: { equals: 'Fixed' },
          },
        },
        {
          pricingSettings: {
            pricingType: {
              is_empty: true,
            },
          },
        },
      ],
    },
  ],
});

interface AvailableTicketingOptionsHook {
  filter?: TicketingOptionFilter;
  skip?: boolean;
  earlyBirdValidated?: boolean;
}
export function useAvailableTicketingOption({
  filter,
  skip,
  earlyBirdValidated,
}: AvailableTicketingOptionsHook) {
  const nowISO = useMemo(() => convertToUTCGeo(DateTime.now().toISO()), []);
  const filters = useMemo((): TicketingOptionFilter => {
    const filtersCollection: TicketingOptionFilter[] = [];
    if (filter) filtersCollection.push(filter);
    if (!earlyBirdValidated) filtersCollection.push(buildDateFilter(nowISO));
    return { AND: filtersCollection };
    // TODO: check earlyBirdValidated added
  }, [earlyBirdValidated, filter, nowISO]);

  const { data: response, loading } = useTicketingOptionListQuery({
    skip: skip ?? false,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    variables: { filter: filters },
  });

  const data = useMemo(() => (response?.options.items ?? []) as TicketingOption[], [response]);

  return {
    data,
    loading,
  };
}

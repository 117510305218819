import React from 'react';

import { formatMoney } from '@vizsla/utils';
import { TopTeamMetricsLeaderboardItem } from '@vizsla/graphql';

import { LeaderBoardCard } from '../LeaderBoardCard';

interface MostAverageDonationsCardProps {
  data: TopTeamMetricsLeaderboardItem;
  loading: boolean;
}
export function MostAverageDonationsCard({ data, loading }: MostAverageDonationsCardProps) {
  const description = React.useMemo(() => {
    return `${formatMoney(data?.amount)} Average`;
  }, [data?.amount]);

  return (
    <LeaderBoardCard
      title="Highest Average Donation"
      loading={loading}
      description={description}
      name={data?.name ?? ''}
      avatar={data?.avatar ?? ''}
    />
  );
}

import React from 'react';

import { TopTeamMetricsLeaderboardItem } from '@vizsla/graphql';

import { LeaderBoardCard } from '../LeaderBoardCard';

interface MostDonorCardProps {
  data: TopTeamMetricsLeaderboardItem;
  loading: boolean;
}

export function MostDonorCard({ data, loading }: MostDonorCardProps) {
  const description = React.useMemo(() => {
    return `${data?.amount} Donors`;
  }, [data?.amount]);

  return (
    <LeaderBoardCard
      title="Most Donors"
      loading={loading}
      description={description}
      name={data?.name ?? ''}
      avatar={data?.avatar ?? ''}
    />
  );
}

import React from 'react';
import Skeleton from '@mui/material/Skeleton';

import { Container } from './PurchaseDescriptorHeader.style';

export const PurchaseDescriptorHeaderSkeleton = () => (
  <Container>
    <Skeleton variant="text" width={250} height={46} />
    <Skeleton variant="text" width={350} height={64} />
  </Container>
);

import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';

import { RegistrationOption, useCheckSoldOutRegistrationLazyQuery } from '@vizsla/graphql';
import { fillRegistrationOption, formatMoney, ResolvePriceFromSettings } from '@vizsla/utils';
import { CounterDisabled } from '@vizsla/components';
import {
  selectAllRegistrations,
  ShoppingCartData,
  ShoppingCartRegistrationData,
  useShoppingCartState,
  CartStore,
  useNotification,
  useMediaBreakpoints,
} from '@vizsla/hooks';
import { CartItem } from '@vizsla/types';

import {
  Container,
  Content,
  Description,
  MoreDetailsLink,
  ReceiptOutlinedIcon,
  PriceText,
  OptionsDivider,
  CounterContainer,
  SoldOut,
  PurchaseContent,
  DetailsContainer,
} from './RegistrationsOptionItem.styles';

const formatPrice = (price: number) => {
  if (price === 0) return 'FREE';
  return formatMoney(price, 2);
};

const selectRegistrationsOptionItemState = (
  optionId: string,
  state: CartStore<ShoppingCartData>,
) => {
  const allRegistration = selectAllRegistrations(state).length;
  const attendees = selectAllRegistrations(state).filter(i => i.option.id === optionId);
  const countOfAttendees = attendees.length;
  return {
    count: countOfAttendees,
    isFirstRegistration: allRegistration === 0,
    attendees,
  };
};

interface RegistrationsOptionItemProps {
  user: any;
  option: RegistrationOption;
  onMoreDetailsClick?: () => void;
  onIncrease?: (registration: ShoppingCartRegistrationData) => void;
  onDecrease?: (registration: CartItem<ShoppingCartRegistrationData>) => void;
  isSoldOut: boolean | null | undefined;
  experienceId: string;
}

export function RegistrationsOptionItem(props: RegistrationsOptionItemProps) {
  const [allAttendeeSlotsFilled, setAllAttendeeSlotsFilled] = useState(false);
  const selectState = React.useCallback(
    state => selectRegistrationsOptionItemState(props.option.id!, state),
    [props.option.id],
  );
  const [checkSoldOut, { loading }] = useCheckSoldOutRegistrationLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const { isSm } = useMediaBreakpoints({ custom: 350 });

  const { count, attendees, isFirstRegistration } = useShoppingCartState(selectState);
  const toaster = useNotification();

  const price = React.useMemo(() => {
    if (!props.option.pricingSettings) return 0;
    return ResolvePriceFromSettings(props.option.pricingSettings, DateTime.local());
  }, [props.option.pricingSettings]);

  const priceFormatted = formatPrice(price);

  const handleIncrease = async () => {
    checkSoldOut({
      variables: {
        amountOfRegistered: count + 1,
        experienceId: props.experienceId,
        registerOptionId: props.option.id,
      },
    }).then(res => {
      const registerOption = res?.data?.checkSoldOutRegistration?.registrations ?? [];
      const isSoldOut = registerOption[0]?.isSoldOut;

      if (!isSoldOut) {
        const registration = fillRegistrationOption({
          option: { name: props.option.name!, id: props.option.id! },
          subtype: 'RegOption',
          price,
          user: isFirstRegistration ? props.user : undefined,
        });
        props.onIncrease?.(registration);
      } else {
        toaster.error('The selected registration option has reached maximum capacity.');
        setAllAttendeeSlotsFilled(true);
      }
    });
  };
  const handleDecrease = () => {
    const lastRegistration = attendees.at(-1);
    if (lastRegistration) props.onDecrease?.(lastRegistration);
    setAllAttendeeSlotsFilled(false);
  };

  return (
    <Container>
      <Content>
        {!isSm && <ReceiptOutlinedIcon />}

        <Description>
          <Typography variant="subtitle1">{props.option.name}</Typography>

          {!isSm && (
            <MoreDetailsLink variant="body2" onClick={props.onMoreDetailsClick}>
              More Details
            </MoreDetailsLink>
          )}

          {allAttendeeSlotsFilled && (
            <Typography paragraph m={0} variant="caption" color="error" align="center" width="100%">
              No more spots available for new attendees.
            </Typography>
          )}
        </Description>
      </Content>
      {!isSm && (
        <PurchaseContent>
          <PriceText variant="subtitle1">{priceFormatted}</PriceText>

          <OptionsDivider />
          <CounterContainer>
            {props.isSoldOut && <SoldOut>Sold out</SoldOut>}

            <CounterDisabled
              value={count}
              onIncrease={handleIncrease}
              onDecrease={handleDecrease}
              isDisabled={loading}
              min={props.isSoldOut ? 0 : (props.option.attendeesPerRegistration ?? 0)}
              max={
                props.isSoldOut || allAttendeeSlotsFilled
                  ? 0
                  : (props.option.maximumRegistrationPerOrder ?? 100000)
              }
            />
          </CounterContainer>
          <DetailsContainer />
        </PurchaseContent>
      )}

      {isSm && (
        <PurchaseContent>
          <DetailsContainer>
            <PriceText variant="subtitle1">{priceFormatted}</PriceText>
            <MoreDetailsLink variant="body2" onClick={props.onMoreDetailsClick}>
              More Details
            </MoreDetailsLink>
          </DetailsContainer>
          <CounterContainer>
            {props.isSoldOut && <SoldOut>Sold out</SoldOut>}

            <CounterDisabled
              value={count}
              onIncrease={handleIncrease}
              onDecrease={handleDecrease}
              isDisabled={loading}
              min={props.isSoldOut ? 0 : (props.option.attendeesPerRegistration ?? 0)}
              max={
                props.isSoldOut || allAttendeeSlotsFilled
                  ? 0
                  : (props.option.maximumRegistrationPerOrder ?? 100000)
              }
            />
          </CounterContainer>
        </PurchaseContent>
      )}
    </Container>
  );
}

import React from 'react';
import { Grid, Typography, Skeleton } from '@mui/material';
import { useLazyQuery } from '@apollo/client';

import { MODALS } from '@vizsla/constants';
import { useCurrentUser, useJoinTeamFundraising, useModal, useNotification } from '@vizsla/hooks';
import { AsyncContent, Modal } from '@vizsla/components';
import { TeamFundraising, USER_ATTENDEE_EXPERIENCE } from '@vizsla/graphql';

import { useFundraiserLandingData } from '../hooks';

export function ConfirmationJoiningTeamDialog() {
  const { joinTeam, loading: loadingMutation } = useJoinTeamFundraising();
  const toast = useNotification();

  const { isOpen, closeModal, args } = useModal(MODALS.JOINING_TEAM_MODAL);
  const {
    team,
    experience,
    loading: landingDataLoading,
  } = useFundraiserLandingData(args?.team?.id);
  const { user } = useCurrentUser();
  const [findAttendeeByFilter] = useLazyQuery(USER_ATTENDEE_EXPERIENCE);
  const existsAttendeeInExperience = async () => {
    if (!user!.id || !experience) return false;
    const resultAttendee = await findAttendeeByFilter({
      variables: {
        userId: user!.id,
        experienceId: experience?.id,
      },
    });

    if (resultAttendee?.data.attendeesList) {
      return resultAttendee?.data.attendeesList.items[0];
    }

    return null;
  };

  const save = async () => {
    if (!team) return;

    try {
      const attendee = await existsAttendeeInExperience();

      if (!attendee) {
        toast.error('You must be registred as Attendee of the experience to join this team');
        closeModal();
        return;
      }

      const teamMembership = await joinTeam({ attendee, team: team as TeamFundraising });

      if (!teamMembership) {
        toast.error(`Team "${team.name}" could not be selected`);
        closeModal();
        return;
      }

      toast.success(`Now you're part of the "${team.name}" team`);
      window.location.reload();
      closeModal();
    } catch (err) {
      console.error(err);
      toast.error('Something fail when trying to select the team');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Content>
        <Grid
          sx={{ padding: theme => theme.spacing(4, 0, 0, 0) }}
          container
          justifyContent="center"
        >
          <AsyncContent
            loading={landingDataLoading}
            skeleton={() => <Skeleton width="100%" height={40} variant="text" />}
          >
            <Typography variant="body1">
              Are you sure you want to join team {args?.objectType && `the ${args?.objectType}`}
              <b>{team?.name ?? 'this object'}</b>?
            </Typography>
          </AsyncContent>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Modal.Action type="secondary" content="Cancel" onAction={closeModal} />
        <Modal.Action
          type="primary"
          content="Join"
          onAction={save}
          loading={loadingMutation || landingDataLoading}
        />
      </Modal.Actions>
    </Modal>
  );
}

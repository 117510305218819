import { useLazyQuery } from '@apollo/client';

import { VERIFY_EARLY_CODE } from 'src/graphql/VerifyEarlyCode';

export const useEarlyBirdVerification = () => {
  const [executeQuery, { loading }] = useLazyQuery(VERIFY_EARLY_CODE, {
    fetchPolicy: 'cache-first',
  });

  const verifyEarlyBirdCode = async (code: string, experienceId: string) => {
    const { data } = await executeQuery({
      variables: {
        code,
        experienceId,
      },
    });
    return data?.verifyEarlyBirdCode?.valid;
  };

  return {
    verifyEarlyBirdCode,
    loading,
  };
};

import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { Footer, Header } from '../components/shared';

const TEMP_STYLES = {
  headerBackground: '#fff', // header background
  accentColor: '#EC308C', // buttons, top border below image
  textLinksColor: '#EC308C', // text links color
  pageBackground: '#fff',
  headerLogoAlign: 'left', // left | center
};

const useStyles = makeStyles<Theme>({
  root: {
    minHeight: '100%',
    display: 'grid',
    gridTemplateAreas: '"header" "content" "footer"',
    gridTemplateRows: '64px 1fr auto',
    zIndex: 1,
  },
  content: {
    gridArea: 'content',
  },
  footer: {
    gridArea: 'footer',
  },
});

export const MainLayout: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header styles={TEMP_STYLES} />

      <main className={classes.content}>{children}</main>

      <footer className={classes.footer}>
        <Footer />
      </footer>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  TopTeamMetricsLeaderboardItem,
  useTopTeamMetricsLeaderboardFundraisingLazyQuery,
} from '@vizsla/graphql';

import { TeamMetricsLeaderboardProps } from 'src/types/team';

export function useSelectedTeamMetricsLeaderboard() {
  const [data, setData] = useState<TeamMetricsLeaderboardProps>();

  const [loading, setLoading] = useState(false);

  const [executeQuery] = useTopTeamMetricsLeaderboardFundraisingLazyQuery();

  const { teamId } = useParams<{ teamId: string }>();

  const refetch = React.useCallback(async () => {
    if (!teamId) return;

    setLoading(true);

    const response = await executeQuery({
      variables: { teamId },
    });

    setData({
      totalDonors: response.data?.TopTeamMetricsLeaderboard
        ?.mostDonor as TopTeamMetricsLeaderboardItem,
      totalDonations: response.data?.TopTeamMetricsLeaderboard
        ?.topFundraiser as TopTeamMetricsLeaderboardItem,
      average: response.data?.TopTeamMetricsLeaderboard
        ?.highestAverageDonation as TopTeamMetricsLeaderboardItem,
    });
    setLoading(false);
  }, [executeQuery, teamId]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return {
    data,
    loading,

    refetch,
  };
}

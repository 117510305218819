import styled from '@emotion/styled';

// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// Content

export const Content = styled(Container)``;

// Buttons

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;

  button {
    margin: 0;
  }
`;

import { useEffect, useMemo } from 'react';

import {
  ExperienceLanding,
  experienceLandingReducer,
  ExperienceLandingSkeleton,
} from '@vizsla/components';
import { useExperienceId, useModal } from '@vizsla/hooks';
import { TemplateType, CampaignStatus, CampaignType, CampaignFaqAllocation } from '@vizsla/types';
import { ExperienceLandingProvider, LandingStateProvider } from '@vizsla/providers';

import { useLandingState } from 'src/hooks/landing';
import { useExperienceById } from 'src/hooks/experience';
import { useCurrentPublicCampaign, usePublicCampaignFAQs } from 'src/hooks/campaign';
import { MODALS } from 'src/constants/modals';

import { CampaignStatusPass } from '../../modals/CampaignStatusModal';

export function ExperienceLandingPage() {
  const { openModal } = useModal();
  const experienceId = useExperienceId();
  const { experience, loading } = useExperienceById(experienceId);
  const { campaign, loading: campaignLoading } = useCurrentPublicCampaign();
  const { templates } = useLandingState();
  const experienceHeaderImage = experience?.experienceHeaderImage;
  const experienceHeaderImages = experienceHeaderImage?.fileId ? [experienceHeaderImage] : [];
  const { campaignFaqExperience } = usePublicCampaignFAQs(
    CampaignFaqAllocation.Donor,
    experience?.id ?? '',
  );

  const experienceLandingTemplate = templates?.find(template => {
    if (campaign?.campaignType === CampaignType.impactGiving) {
      return template?.templateType?.type === TemplateType.CAMPAIGN;
    }
    return template?.templateType?.type === TemplateType.EXPERIENCE;
  });
  const experienceLandingStructure = experienceLandingTemplate?.structure?.appSchema;
  const campaignId = campaign?.id as string;
  const isLoading = loading || campaignLoading || templates.length === 0;
  const campaignStatus = campaign?.campaignStatus;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (campaignStatus && campaignStatus !== CampaignStatus.live) {
      openModal(MODALS.CAMPAIGN_STATUS_MODAL, {
        campaignStatus,
      });
    }
  }, [campaignStatus, openModal]);

  const experienceData = useMemo(() => {
    if (experience && campaign) return { ...experience, campaign: { ...campaign } };
    return {};
  }, [experience, campaign]);

  if (isLoading || campaignLoading) {
    return <ExperienceLandingSkeleton />;
  }

  const type =
    campaign?.campaignType === CampaignType.impactGiving
      ? TemplateType.CAMPAIGN
      : TemplateType.EXPERIENCE;

  return (
    <ExperienceLandingProvider
      campaignId={campaignId}
      experienceId={experienceId}
      templateType={type}
    >
      <CampaignStatusPass />

      <LandingStateProvider landingReducer={experienceLandingReducer}>
        <ExperienceLanding
          experience={experienceData}
          loading={loading}
          experienceLandingState={experienceLandingStructure}
          headerImages={experienceHeaderImages}
          campaingFaqExperience={campaignFaqExperience}
        />
      </LandingStateProvider>
    </ExperienceLandingProvider>
  );
}

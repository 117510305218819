import React from 'react';
import { Grid, Typography } from '@mui/material';

export const Headline = () => {
  return (
    <Grid item xs={12}>
      <Typography
        variant="h1"
        sx={{
          fontWeight: 900,
          textAlign: 'center',
          color: '#07C27D',
        }}
      >
        THANK YOU!
      </Typography>
    </Grid>
  );
};

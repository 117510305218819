import { useState, useMemo, useEffect } from 'react';

import {
  useTeamFundraisingRaisedQuery,
  useGetAttendeesTotalRaisedByTeamQuery,
} from '@vizsla/graphql';

import { RaisedTable } from '../components';
import { MEMBERS, TEAM_RANKED } from '../constants/raisedTypes';

export const useTeamRankingTables = ({ experience, team }) => {
  const [membersRaisedData, setMembersRaisedData] = useState<any>([]);
  const { refetch } = useGetAttendeesTotalRaisedByTeamQuery({
    skip: true,
  });
  const teamsByExperience = useTeamFundraisingRaisedQuery({
    variables: { experienceId: experience?.id ?? '' },
  });

  const rankedTeams = useMemo(() => {
    if (!teamsByExperience?.data?.teamFundraisingsList.items) return [];

    const sortedItems = [...teamsByExperience.data.teamFundraisingsList.items];
    sortedItems.sort((a, b) => (b?.totalRaised || 0) - (a?.totalRaised || 0));
    const itemsWithRank = sortedItems.map((item, index) => ({
      ...item,
      rank: index + 1,
    }));

    return itemsWithRank;
  }, [teamsByExperience?.data?.teamFundraisingsList?.items]);

  useEffect(() => {
    const fetchMembersData = async () => {
      if (!team?.members?.items) return;

      try {
        const promises = team.members.items.map(async member => {
          if (!member?.attendee?.id || !team?.id) return member;
          const { data } = await refetch({ attendeeId: member.attendee.id, teamId: team.id });
          const donationAllocations = data?.getAttendeesTotalRaisedByTeam?.items || [];
          const totalRaised = donationAllocations.reduce(
            (prev, curr) => prev + (curr?.amount || 0),
            0,
          );
          return { ...member, totalRaised };
        });

        const results = await Promise.all(promises);
        const sortedItems = [...results];
        sortedItems.sort((a, b) => (b?.totalRaised || 0) - (a?.totalRaised || 0));

        const updatedMembersData = sortedItems.map((item, index) => ({
          ...item,
          rank: index + 1,
        }));

        setMembersRaisedData(updatedMembersData);
      } catch (error) {
        console.error('Error fetching members data:', error);
      }
    };

    fetchMembersData();
  }, [refetch, team]);

  const currentTeamRank = rankedTeams.find(item => item.id === team.id);

  const teamsModalTable = (
    <RaisedTable styles={{}} members={membersRaisedData as any} type={MEMBERS} team={team} />
  );

  const teamsRankedModalTable = (
    <RaisedTable styles={{}} members={rankedTeams as any} type={TEAM_RANKED} team={team} />
  );

  return { currentTeamRank, teamsModalTable, teamsRankedModalTable, rankedTeams };
};

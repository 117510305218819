import { useLayoutEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { getCurrentTimeZone } from '@vizsla/utils';
import { useAppAuth } from '@vizsla/hooks';

import { AppRouter } from './providers';
import { ConfirmEmailModal, LoginModal, SignupModal, ForgotPasswordModal } from './modals';

export function App() {
  const [firstRoute, setFirstRoute] = useState<string | null>(null);
  const { location } = window;
  const { isAuthenticated } = useAppAuth();

  useLayoutEffect(() => {
    getCurrentTimeZone();
    setFirstRoute(location.pathname);

    if (isAuthenticated && firstRoute !== null) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, location.pathname]);

  return (
    <BrowserRouter>
      <AppRouter />
      <LoginModal firstRoute={firstRoute} />
      <SignupModal />
      <ForgotPasswordModal />
      <ConfirmEmailModal />
    </BrowserRouter>
  );
}
